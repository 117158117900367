import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { productImgUrl } from "./../../url/UrlHelpes";
import Footer from "../../layouts/footer/footer";
import HashLoader from 'react-spinners/HashLoader';
import imgload from "../../assets/images/logo-2.png";
import HeaderUpdate from "../../layouts/Header/HeaderUpdate";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import imgagelogo from "./../../assets/images/logo-page-detail.png";
import {
  fetchProducts,
  filterProductsByCategory,
} from "../../features/product/ProductsSlice";
import Filtermiels from "./filtermiels";
import "../../assets/css/Nos-Miels/listemiels.css";

const Listemiels = () => {
  const { title } = useParams();
  const { i18n, t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(8);
  const dispatch = useDispatch();
  const { products, status, error } = useSelector((state) => state.products);
  const selectedCategory = "Miel";

  // Determine the language ID based on the current language
  const languageId = i18n.language === 'fr' ? 1 : 2;

  const ProductMiles = products.filter(
    (product) => product.categorie[0].title === "Miel"
  ).sort((a, b) => a.title.localeCompare(b.title));

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    scrollToTop();
  };

  useEffect(() => {
    // Fetch products with the correct language ID
    dispatch(fetchProducts(languageId));
  }, [dispatch, languageId]);

  useEffect(() => {
    setCurrentPage(1);
    updateItemsPerPage();
    window.addEventListener("resize", updateItemsPerPage);
    return () => window.removeEventListener("resize", updateItemsPerPage);
  }, [title]);

  useEffect(() => {
    if (selectedCategory) {
      dispatch(filterProductsByCategory(selectedCategory));
    }
  }, [selectedCategory, dispatch]);

  const updateItemsPerPage = () => {
    setItemsPerPage(window.innerWidth <= 500 ? 8 : 12);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = ProductMiles.slice(indexOfFirstItem, indexOfLastItem);

  if (status === 'loading') {
    return (
      <div className="loading-container" style={{ height: 'vh100' }}>
        <div className="row w-100">
          <div className="col-md-12 justify-content-center align-content-center">
            <img className='w-25' src={imgload} alt="Loading" />
          </div>
          <div className="col-md-12 ">
            <p className="text-center ">
              <HashLoader className="m-auto" size={50} color={"#cf9a52"} loading={status === 'loading'} />
            </p>
          </div>
        </div>
      </div>
    );
  } else if (status === 'succeeded') {
    return (
      <>
        <HeaderUpdate />
        <h1 className="title-miels text-center pt-3" id="listemil">
          {t("listemiels.title")}
        </h1>
        <img
          className="logodetail mb-3"
          src={imgagelogo}
          alt=""
          style={{ display: "block", margin: "auto" }}
        />
        <div className="container-fluid conatiner-liste-miels">
          <div className="row">
            <div className="col-md-3">
              <Filtermiels />
            </div>
            <div className="col-md-9 list-mm">
              {ProductMiles.length === 0 ? (
                <div className="text-center mess-not">
                  <h2 className="mb-3 mess-not">{t("message.Aucunmielstrouvé")}</h2>
                  <p className="lead mess-p">
                  {t("message.des")}
                  </p>
                </div>
              ) : (
                <div className="container justify-content-start conatiner-product p-1">
                  <div className="row w-100 d-flex row-mobile-miles row-cols-4">
                    {currentItems.map((item) => {
                      // Find the translation for the current language
                      const translation = item.translates.find(t => t.language.id === languageId) || {};

                      return (
                        <div
                          className="col flex-wrap justify-content-start mb-5 product-item"
                          key={item.id}
                        >
                          <div className="card card-image border-0">
                            <Link to={`/detail-miel/${item.id}`}>
                              <div className="card card-image border-0">
                                <img
                                  src={`${productImgUrl}/${item?.image}`}
                                  className="card-img-top"
                                  alt="Card Image"
                                />
                              </div>
                            </Link>
                            <div className="card-body d-flex flex-column">
                              <Link
                                to={`/detail-miel/${item.id}`}
                                className="text-black mt-auto"
                                style={{ textDecoration: "none" }}
                              >
                                <h5 className="title title-product">
                                  {i18n.language === 'fr' ? item.title : (translation.title || item.title)}
                                </h5>
                              </Link>
                            </div>
                          </div>
                          <div className="col px-0 text-end shoppingcard d-flex justify-content-center">
                            <div className="p-0 mb-3 d-flex-center">
                              <Link
                                to={`/detail-miel/${item.id}`}
                                className="text-black mt-auto align-self-left lire-plus-miles"
                                onClick={scrollToTop}
                              >
                                {t("listemiels.Lireplus")}
                              </Link>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              {ProductMiles.length > itemsPerPage && (
                <ul className="pagination pagination-list justify-content-center">
                  {Array.from({
                    length: Math.ceil(ProductMiles.length / itemsPerPage),
                  }).map((_, index) => (
                    <li
                      key={index}
                      className={`page-item ${
                        currentPage === index + 1 ? "active" : ""
                      }`}
                    >
                      <button
                        key={index}
                        onClick={() => paginate(index + 1)}
                        className="page-link pagination-link"
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  } else {
    return (
      <div className="text-center">
        {!error ? "No found page" : error}
      </div>
    );
  }
};

export default Listemiels;
