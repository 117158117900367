import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {apiUrl } from './../../url/UrlHelpes'
export const fetchMdDelivery = createAsyncThunk(
  "deliverys/fetchMdDelivery",
  async () => {
    const response = await fetch(`${apiUrl}/deliveries`);
    const data = await response.json();
    return data;
  }
);

const initialState = {
  deliverys: [],
  status: null,
};

const DeliverySlice = createSlice({
  name: "deliverys",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMdDelivery.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchMdDelivery.fulfilled, (state, { payload: deliverys }) => {
        state.status = "succeeded";
        state.deliverys = deliverys;
      })
      .addCase(fetchMdDelivery.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default DeliverySlice.reducer;
