// LoginForm.js
import React, { useState } from "react";
import HeaderUpdate from "../../../layouts/Header/HeaderUpdate";
import { apiUrl } from "../../../url/UrlHelpes";
import "./LoginForm.css";
import Footer from "../../../layouts/footer/footer";
import { Link, useNavigate } from "react-router-dom";
import ProgressBar from "../../../views/ProgressBar ";
import useApiRequest from "@mbs-dev/api-request";
import { useTranslation } from "react-i18next";

const LoginForm = () => {
  const { t } = useTranslation();
  const { apiRequest } = useApiRequest();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formValid = validateForm();
    if (!formValid) return;

    try {
      const response = await fetch(`${apiUrl}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const data = await response.json();
        setErrors({ email: data.message });
        return;
      }

      const { token } = await response.json();

      localStorage.setItem("mbs_user_token", token);
      localStorage.setItem("isAuthenticated", "true");

      const meResponse = await apiRequest({
        route: `${apiUrl}/me`,
        method: "GET",
        requiresAuth: true,
        token: localStorage.getItem("mbs_user_token"),
      });

      if (meResponse.status === 200) {
        localStorage.setItem("mbs_user", JSON.stringify(meResponse.data));
      }

      navigate("/");
    } catch (error) {
      // console.error("Error:", error);
    }
  };

  const validateForm = () => {
    let formValid = true;
    const newErrors = {};

    if (!formData.email.trim()) {
      newErrors.email = "Veuillez saisir votre email";
      formValid = false;
    } else if (!validateEmail(formData.email)) {
      newErrors.email =
        "Format d'email invalide. Veuillez saisir une adresse email valide.";
      formValid = false;
    }

    if (!formData.password.trim()) {
      newErrors.password = "Veuillez saisir votre mot de passe";
      formValid = false;
    }

    setErrors(newErrors);
    return formValid;
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const [activeTab, setActiveTab] = useState("login");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <HeaderUpdate />
      <ProgressBar />
      <div className="container pt-5" id="login-frm">
        <div className="row align-items-center">
          <div className="col-md-12 col-lg-12 mb-3 text-center">
            <h2 className="fw-bold fs-1 mt-5">
              <div
                className="d-flex justify-content-center fr-mobile"
                style={{ fontSize: "30px", fontFamily: "Montserrat-Regular" }}
              >
                <Link
                  to="/login"
                  className={`nav-link sign-in-up ${
                    activeTab === "login" ? "active-form" : "inactive-form"
                  }`}
                  onClick={() => handleTabClick("login")}
                >
                  {t("Auth.login")}/
                </Link>
                <p></p>
                <p className="mx-2"></p>
                <Link
                  to="/register"
                  className={`nav-link sign-in-up ${
                    activeTab === "register" ? "active-form" : "inactive-form"
                  }`}
                  onClick={() => handleTabClick("register")}
                >
                  {t("Auth.register")}
                </Link>
              </div>
            </h2>
          </div>
        </div>
        <div className="row justify-content-center">
          <form className="login-form logi-frm" onSubmit={handleSubmit}>
            <div className="col-12 mb-2">
              <div className="input-box">
                <label htmlFor="email" className="form-label">
                {t("Auth.email")} <span className="clr-red">*</span>
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
                {errors.email && (
                  <div className="error-message error-sty">{errors.email}</div>
                )}
              </div>
            </div>
            <div className="col-12 mt-3">
              <div className="input-box">
                <label htmlFor="password" className="form-label">
                {t("Auth.motpass")}<span className="clr-red">*</span>
                </label>
                <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                />
                {errors.password && (
                  <div className="error-message error-sty">
                    {errors.password}
                  </div>
                )}
              </div>
            </div>
            <div className="col-12 mt-2 form-check text-end">
              <a
                href="/forgot-password"
                style={{ color: "#d59a48" }}
                className="clr-pink nav-link forgot-password"
              >
                {t("Auth.motpassobli")}
              </a>
            </div>
            <div className="col-12 mt-4">
              <button type="submit" className="btn submit-btn">
              {t("Auth.connecter")}
              </button>
              <Link
                to="/register"
                className="btn submit-btn inscri-btn"
                style={{ margin: "10px" }}
              >
                {t("Auth.inscrivez")}
              </Link>
            </div>
          </form>
        </div>
      </div>
      <br />
      <br />
      <Footer />
    </>
  );
};

export default LoginForm;
