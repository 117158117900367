import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { apiUrl } from "./../url/UrlHelpes";
import MessageSuccess from './../function/addMessageSuccess';
import "./../assets/css/ContactPage.css";
import logo from './../assets/images/logo-page-detail.png';
import Footer from "../layouts/footer/footer";
import HeaderUpdate from '../layouts/Header/HeaderUpdate';
import CaptchaComponent from './CaptchaComponent';

const ContactPage = () => {
    const { t } = useTranslation();
    const [captchaValid, setCaptchaValid] = useState(false);
    const [loading, setLoading] = useState(false);
    const { register, handleSubmit, formState: { errors, isDirty, isValid } } = useForm();
    const isChecked = isDirty && !errors['gridCheck'];

    const handleCaptchaChange = (inputValue, actualCaptcha) => {
        setCaptchaValid(inputValue === actualCaptcha);
    };

    const onSubmit = async (data) => {
        try {
            setLoading(true);

            if (!captchaValid) {
                throw new Error(t('contact.captchaError'));
            }

            const currentDate = new Date();
            const formattedDate = currentDate.toISOString();

            const requestBody = {
                nom: data['nom'],
                prenom: data['prenom'],
                email: data['your-email'],
                message: data['your-message'],
                // user: 'api/users/1',
                getResponse: 0,
                createdAt: formattedDate
            };

            const response = await fetch(`${apiUrl}/user_emails`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody)
            });

            if (response.ok) {
                MessageSuccess(t('contact.success'));
            } else {
                throw new Error(t('contact.failure'));
            }
        } catch (error) {
            // console.error('Error:', error);
            // Handle error gracefully, display to the user if needed
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <HeaderUpdate />
            <div id='container-contact' className="container-fluid">
                <div className="row justify-content-center" style={{ backgroundColor: "#f9f9f7" }}>
                    <div className="col-lg-9">
                        <div className="col-md-12">
                            <h1 className="text-center mb-3">{t('contact.title')}</h1>
                            <div className="col-md-12 mt-3">
                                <img
                                    src={logo}
                                    className="img-fluid m-auto d-block"
                                    alt=""
                                    style={{ width: "20%" }}
                                />
                            </div>
                            <h3 className="text-center mt-3 m-auto w-50 mb-3">{t('contact.subtitle')}</h3>
                        </div>
                        <form className='form-content' onSubmit={handleSubmit(onSubmit)}>
                            <div className="row g-3 mt-5 mb-5">
                                <div className="col-md-6">
                                    <label htmlFor="nom" className="form-label label-form-contact">{t('contact.name')}<span className="required clr-red">*</span></label>
                                    <input type="text" className="form-control input-form-contact" id="nom" name="nom" {...register("nom", { required: true })} />
                                    {errors['nom'] && <span className="text-danger">{t('contact.error')}</span>}
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="prenom" className="form-label label-form-contact required fs-6">
                                        {t('contact.name2')}
                                        <span className="required clr-red">*</span>
                                    </label>
                                    <input type="text" className="form-control input-form-contact" id="prenom" name="prenom" {...register("prenom", { required: true })} />
                                    {errors['prenom'] && <span className="text-danger">{t('contact.error')}</span>}
                                </div>
                                <div className="col-md-12">
                                    <label htmlFor="your-email" className="form-label label-form-contact">{t('contact.email')}<span className="required clr-red">*</span></label>
                                    <input type="email" className="form-control input-form-contact" id="your-email" name="your-email" {...register("your-email", { required: true, pattern: /^\S+@\S+$/i })} />
                                    {errors['your-email'] && <span className="text-danger">{t('contact.error')}</span>}
                                </div>
                                <div className="col-12">
                                    <label htmlFor="your-message" className="form-label label-form-contact">{t('contact.message')}<span className="required clr-red">*</span></label>
                                    <textarea className="form-control input-form-contact" id="your-message" name="your-message" rows="5" {...register("your-message", { required: true })}></textarea>
                                    {errors['your-message'] && <span className="text-danger">{t('contact.error')}</span>}
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="captcha" className="form-label label-form-contact">code de sécurité<span className="required clr-red">*</span></label>
                                    <CaptchaComponent onChange={handleCaptchaChange} />
                                    {errors['captcha'] && <span className="text-danger">{t('contact.captchaError')}</span>}
                                </div>
                                <div className="col-12">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id="gridCheck" {...register("gridCheck", { required: true })} />
                                        <label className="form-check-label" htmlFor="gridCheck">{t('contact.check')}</label>
                                    </div>
                                    {errors['gridCheck'] &&
                                        <div className="form-check">
                                            <span className="text-danger">{t('contact.checked')}</span>
                                        </div>
                                    }
                                </div>
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <button
                                                disabled={!isValid || loading}
                                                type="submit"
                                                className="btn btn-contact-Envoyer w-25 fw-bold"
                                            >
                                                {loading ? t('contact.loading') : t('contact.btn')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default ContactPage;
