import { configureStore } from "@reduxjs/toolkit";
import productsreducer from "../features/product/ProductsSlice";
import productsByIdreducer from "../features/product/productByIdSlice";
import SliderCoverSlicereducer from "../features/slidercover/SliderCoverSlice";
import ProductWhereWeightreducer from "../features/product/ProductWhereWeightSlice";
import Packreducer from "../features/pack/PackSlice";
import categorieSlice from "../features/categories/CategorieSlice";
import blogsSlice from "../features/blog/BlogSlice";
import mielsReducer from "../features/miels/MielsSlice";
import CategoryWeightreducer from "../features/product/CategoryWeightSlice";
import PackByIdreducer from "../features/pack/PackByIdSlice";
import testreducer from "../features/product/switchproductById";
import productCofReducer from "../features/product/CoffertsSlice";
import userReducer from "../features/Auth/userSlice";
import ProductMielReducer from "../features/product/ProductMielByIdCartSlice";
import ProductCoffertReducer from "../features/product/ProductCoffretByIdCartSlice";
import DeliveryReducer from "../features/delivery/DeliverySlice";
import productsCompositionOnereducer from "../features/product/ProductCompositionOneByIdSlice";
import productsCompositionTowreducer from "../features/product/ProductCompositionTowByIdSlice";
import productsOneCompositionTowreducer from "../features/product/ProductOneCompositionOneByIdSlice";
import variantByIdreducer from "../features/product/variantByIdSlice";

export const store = configureStore({
  reducer: {
    products: productsreducer,
    product: productsByIdreducer,
    cover: SliderCoverSlicereducer,
    productwhereweight: ProductWhereWeightreducer,
    packs: Packreducer,
    categorie: categorieSlice,
    blogs: blogsSlice,
    miels: mielsReducer,
    categoryweight: CategoryWeightreducer,
    pack: PackByIdreducer,
    productCof: productCofReducer,
    switchproductById: testreducer,
    user: userReducer,
    productmiel: ProductMielReducer,
    productcoffret:ProductCoffertReducer,
    deliverys:DeliveryReducer,
    productCompositionOne:productsCompositionOnereducer,
    productCompositionTow:productsCompositionTowreducer,
    variant:variantByIdreducer,
    productOneCompositionOne:productsOneCompositionTowreducer

  },
});
