import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
  error: null,
  isLoading: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    registerUserStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    registerUserSuccess(state, action) {
      state.isLoading = false;
      state.user = action.payload;
    },
    registerUserFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { registerUserStart, registerUserSuccess, registerUserFailure } = userSlice.actions;

export default userSlice.reducer;