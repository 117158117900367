import React, { useEffect, useState } from "react";
import { Stack, Button } from "react-bootstrap";
import PropagateLoader from 'react-spinners/PropagateLoader';
import { packImgUrl, apiUrl } from './../../url/UrlHelpes';
import './../../assets/css/shopping/CartItems.css';
import axios from "axios";
import { useCart } from "../../context/ShoppingCartContext";
import FormatCurrency from "./../../function/FormatCurrency";

const ItemsPackProduct = ({ index, composition }) => {
    const { removepackFromCart } = useCart();
    const [pack, setPack] = useState(null);
    const [productsData, setProductsData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let isMounted = true; // flag to check if component is mounted
        
        // Fetch pack data
        setLoading(true);
        axios.get(`${apiUrl}/packs/${composition.packID}`)
            .then(response => {
                if (isMounted) {
                    setPack(response.data);
                }
            })
            .catch(error => {
                console.error('Error fetching pack data:', error);
            });

        // Fetch product data for all products in the composition
        const productPromises = composition.Products.map(product =>
            axios.get(`${apiUrl}/products/${product.productId}`)
        );

        Promise.all(productPromises)
            .then(responses => {
                if (isMounted) {
                    setProductsData(responses.map(res => res.data));
                }
            })
            .catch(error => {
                console.error('Error fetching product data:', error);
            })
            .finally(() => {
                if (isMounted) {
                    setLoading(false);
                }
            });

        return () => {
            isMounted = false;
        };
    }, [composition.packID, composition.Products]);

    if (loading){
        return(
            <div className="text-center">
                <p className='pt-5 pb-5'>
                    <PropagateLoader className=''  size={10} color={"#cf9a52"}  />
                </p>
            </div>
        )
    }

    if (!pack) return null;

    const calculateTotalPrice = (product, quantity) => {
        if (!product) return 0;
        const variant = product.variant.find(v => v.label === "130"); // Adjust the label as needed
        return variant ? parseFloat(variant.purchasePriceTTC) * quantity : 0;
    };

    // Calculate the total price for all products in the composition
    const totalPrice = composition.Products.reduce((total, product, idx) => {
        const productData = productsData[idx];
        return total + calculateTotalPrice(productData, product.quantity);
    }, 0);

    return (
        <Stack direction="horizontal" gap={2} className="d-flex align-items-center">
            <img
                src={`${packImgUrl}/${pack.image}`}
                alt="cart-img"
                style={{ width: "70px", height: "75px", objectFit: "cover" }}
            />
            <div className="me-auto">
                <div>{pack.title}</div>
                <div className="text-muted" style={{ fontSize: "0.75rem" }}>
                    {composition.Products.map((product, idx) => (
                        <div key={idx}>{productsData[idx]?.title} x {product.quantity}</div>
                    ))}
                </div>
            </div>
            <div>{FormatCurrency(totalPrice)}</div>
            <Button
                variant="outline-danger btn-remove-item"
                size="sm"
                onClick={() => removepackFromCart(index)}
            >
                &times;
            </Button>
        </Stack>
    );
};

export default ItemsPackProduct;
