import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {apiUrl } from './../../url/UrlHelpes'

export const fetchProductWhereWeight = createAsyncThunk(
  "productWhereWeight/fetchProductWhereWeight",
  async () => {
    const response = await fetch(
      `${apiUrl}/listemilescomposition`
    );
    const data = response.json();
    return data;
  }
);

const initialState = {
  productWhereWeight: [],
  status: null,
};

const ProductWhereWeightState = createSlice({
  name: "productWhereWeight",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductWhereWeight.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        fetchProductWhereWeight.fulfilled,
        (state, { payload: productWhereWeight }) => {
          state.status = "succeeded";
          state.productWhereWeight = productWhereWeight;
        }
      )
      .addCase(fetchProductWhereWeight.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default ProductWhereWeightState.reducer;