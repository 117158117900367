// CaptchaComponent.js
import React, { useState, useEffect } from 'react';
import "./../assets/css/ContactPage.css";
const generateCaptcha = () => {
    const chars = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let captcha = '';
    for (let i = 0; i < 6; i++) {
        captcha += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return captcha;
};

const CaptchaComponent = ({ onChange }) => {
    const [captcha, setCaptcha] = useState('');

    useEffect(() => {
        setCaptcha(generateCaptcha());
    }, []);

    return (
        <div>
            <div style={{ fontFamily: 'monospace', fontSize: '24px', letterSpacing: '5px' }}>{captcha}</div>
            <input
                className='form-control input-form-contact'
                type="text"
                onChange={(e) => onChange(e.target.value, captcha)}
                placeholder="Entrez le code"
                required
            />
        </div>
    );
};

export default CaptchaComponent;
