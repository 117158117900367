import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import FranceIcon from "../../assets/images/france.png";
import UKIcon from "../../assets/images/english.png";
import util from "../../assets/images/utilisateur(1).png";
import Paniericon from "../../assets/images/pannier.png";
import logo from "../../assets/images/jadid.png";
import { useCart } from "../../context/ShoppingCartContext";
import { ReactComponent as Search } from "../../assets/images/searchcolor.svg";
import Offcanvas from 'react-bootstrap/Offcanvas';
import "./../../assets/css/Header/headerUpdate.css";

const HeaderUpdate = () => {
  const { i18n, t } = useTranslation();
  const [isSearchVisible, setSearchVisible] = useState(false);
  const [isOffcanvasOpen, setOffcanvasOpen] = useState(false);
  const { openCart, cartQuantity } = useCart();
  const checklogin = localStorage.getItem("mbs_user_token");

  const toggleSearch = () => {
    setSearchVisible(!isSearchVisible);
  };

  const toggleOffcanvas = () => {
    setOffcanvasOpen(!isOffcanvasOpen);
  };

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <>
      <header>
        {/* Language Switcher */}
        <ul className="langues">
          <li>
            <img
              src={FranceIcon}
              onClick={() => changeLanguage("fr")}
              alt="French Language"
              className="language-icon"
            />
          </li>
          <li>
            <img
              src={UKIcon}
              onClick={() => changeLanguage("en")}
              alt="English Language"
              className="language-icon"
            />
          </li>
        </ul>
        <ul className="icons icon-u">
          <li>
            <button
              type="button"
              className="icon-pays border-0"
              style={{ background: "none" }}
              onClick={openCart}
            >
              <img src={Paniericon} alt="miel montet pannier" />
            </button>
            {cartQuantity > 0 && (
              <div
                className="rounded-circle round-circle d-flex justify-content-center align-items-center"
                style={{
                  color: "white",
                  backgroundColor: "#cf9a52",
                  width: "20px",
                  height: "20px",
                  fontSize: "10pt",
                  fontWeight: 800,
                  position: "absolute",
                  top: 25,
                  right: 42,
                  transform: "translate(25%, 25%)",
                  marginRight: 10,
                }}
              >
                {cartQuantity}
              </div>
            )}
          </li>
          <li>
            <Link to={checklogin ? "/profile" : "/login"}>
              <img
                src={util}
                alt="User icon"
                style={{ fontSize: "25px", cursor: "pointer" }}
              />
            </Link>
          </li>
        </ul>
        <div className="clear"></div>
        <div className="marque">
          <div className="leftLines">
            <div className="line"></div>
            <div className="line"></div>
          </div>
          <div className="logo">
            <Link to="/">
              <img src={logo} alt="miel montet logo" />
            </Link>
          </div>
          <div className="rightLines">
            <div className="line"></div>
            <div className="line"></div>
          </div>
          <div className="clear"></div>
        </div>
      </header>
      <nav className="navbar navbar-expand-lg nav-header p-0">
        <button
          className="navbar-toggler tog-btn"
          type="button"
          style={{
            boxShadow: "none",
            color: "#c49c63",
            border: "1px solid #c49c63",
            padding: ".4rem",
          }}
          onClick={toggleOffcanvas}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <Offcanvas show={isOffcanvasOpen} onHide={toggleOffcanvas} placement="end">
          <Offcanvas.Header closeButton>
          </Offcanvas.Header>
          <div className="logo">
            <Link to="/">
              <img src={logo} alt="miel montet logo" />
            </Link>
          </div>
          <Offcanvas.Body>
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <Link to="/categorie-miels" className="nav-link">
                  {t("header.ourHoneys")}
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/categorie-coffrets" className="nav-link">
                  {t("header.ourGiftSets")}
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/composez-votre-coffret-de-miel" className="nav-link">
                  {t("header.yourCompositions")}
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/history" className="nav-link">
                  {t("header.ourStory")}
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/engagement-page" className="nav-link">
                  {t("header.ourCommitments")}
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/value-page" className="nav-link">
                  {t("header.ourValues")}
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/botique" className="nav-link">
                  {t("header.ourBoutique")}
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/nos-actus" className="nav-link">
                  {t("header.ourNews")}
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/contact" className="nav-link">
                  {t("header.contactUs")}
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/entreprises" className="nav-link">
                  {t("header.business")}
                </Link>
              </li>
            </ul>
          </Offcanvas.Body>
        </Offcanvas>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <header className="mt-0 nav-menu" style={{ width: "100%" }}>
            <div className="container-fluid size-li text-center w-100">
              <div className="row justify-content-center text-center" style={{ width: "100%" }}>
                <div className="col-lg-4 text-center">
                  <ul className="mrg">
                    <Link to="/categorie-miels" className="nav-link">
                      <li className="nav-item text-black">{t("header.ourHoneys")}</li>
                    </Link>
                    <Link to="/categorie-coffrets" className="nav-link">
                      <li className="nav-item text-black">{t("header.ourGiftSets")}</li>
                    </Link>
                    <Link to="/composez-votre-coffret-de-miel" className="nav-link">
                      <li className="nav-item text-black">{t("header.yourCompositions")}</li>
                    </Link>
                  </ul>
                </div>
                <div className="col-lg-4 justify-content-center">
                  <ul className="d-inline-flex">
                    <Link to="/history" className="nav-link">
                      <li className="nav-item">{t("header.ourStory")}</li>
                    </Link>
                    <Link to="/engagement-page" className="nav-link">
                      <li className="nav-item">{t("header.ourCommitments")}</li>
                    </Link>
                    <Link to="/value-page" className="nav-link">
                      <li className="nav-item">{t("header.ourValues")}</li>
                    </Link>
                    <Link to="/botique" className="nav-link">
                      <li className="nav-item">{t("header.ourBoutique")}</li>
                    </Link>
                  </ul>
                </div>
                <div className="col-lg-4 align-items-end">
                  <ul className="d-inline-flex right-menu" style={{ float: "right" }}>
                    <Link to="/nos-actus" className="nav-link pb-0">
                      <li className="nav-item mb-0">{t("header.ourNews")}</li>
                    </Link>
                    <Link to="/contact" className="nav-link">
                      <li className="nav-item">{t("header.contactUs")}</li>
                    </Link>
                    <Link to="/entreprises" className="nav-link">
                      <li className="nav-item">{t("header.business")}</li>
                    </Link>
                    <li>
                      <div className="col-sm-auto col-iconsearch pe-0 ps-0" style={{ width: "2rem" }}>
                        <form className="d-flex ps-0 c form-search justify-content-end">
                          <button
                            id="searchButton"
                            style={{ background: "none" }}
                            className="btn-search my-3 my-sm-0 icon-search"
                            type="button"
                            onClick={toggleSearch}
                          >
                            <Search className="icon icon-se" />
                          </button>
                        </form>
                      </div>
                    </li>
                  </ul>
                </div>
                <input
                  id="searchInput"
                  className="form-control justify-content-end input-search input-size mr-sm-3 m-1 me-5"
                  type="search"
                  placeholder={t("header.searchPlaceholder")}
                  aria-label="Search"
                  style={{
                    display: isSearchVisible ? "block" : "none",
                    border: "2px solid #CF9A52",
                  }}
                />
              </div>
            </div>
          </header>
        </div>
      </nav>
    </>
  );
};

export default HeaderUpdate;
