import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { fetchProductById } from "../features/product/productByIdSlice";
import { useCart } from "../context/ShoppingCartContext";

import HeaderUpdate from "../layouts/Header/HeaderUpdate";
import Footer from "../layouts/footer/footer";
import ProgressBar from "./ProgressBar ";
import Description from "../components/DetailDescription/Description";
import HashLoader from "react-spinners/HashLoader";
import Card from "../components/Home/Sliders/CardsSlider";


import { PMultiImgUrl } from "../url/UrlHelpes";

import imgload from "../assets/images/logo-2.png";
import logodetail from "../assets/images/logo-page-detail.png";

import "../assets/css/loading.css";
import "../assets/css/PageDetail/Pagedetail.css";

const Ditail = () => {
  const { addProductToCart, addProductCoffretToCart } = useCart();
  const { id: productId } = useParams();
  const [quantityProduct, setQuantityProduct] = useState(1);
  const dispatch = useDispatch();
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [price, setPrice] = useState(0);
  const { product = {}, status, error } = useSelector((state) => state.product);
  const { t, i18n } = useTranslation();

  const languageId = i18n.language === "fr" ? 1 : 2;

  useEffect(() => {
    if (product.variant && product.variant.length > 0) {
      setSelectedVariant(product.variant[0]);
      setPrice(product.variant[0].purchasePriceTTC);
    }
  }, [product.variant]);

  useEffect(() => {
    dispatch(fetchProductById({ productId, languageId }));
  }, [dispatch, productId, languageId]);

  const handleButtonClick = (action) => {
    if (action === "increment") {
      setQuantityProduct(quantityProduct + 1);
    } else if (action === "decrement" && quantityProduct > 1) {
      setQuantityProduct(quantityProduct - 1);
    }
  };

  const handleRadioChange = (event) => {
    const selectedVariant = product.variant.find(
      (variant) => variant.label === event.target.value
    );
    if (selectedVariant) {
      setPrice(selectedVariant.purchasePriceTTC);
      setSelectedVariant(selectedVariant);
      setQuantityProduct(1);
    }
  };

  // const calculateTotalPrice = () => {
  //   return price.toFixed(2);
  // };

  const handleAddProductToCart = () => {
    if (selectedVariant) {
      const variantId = selectedVariant.id;
      const variantLabel = selectedVariant.label;
      const variantPrice = selectedVariant.purchasePriceTTC;
      addProductToCart(
        productId,
        quantityProduct,
        variantId,
        variantLabel,
        variantPrice
      );
    }
  };

  const handleAddCoffretToCart = () => {
    if (product.variant && product.variant.length > 0) {
      const priceCoffret = product.variant[0].purchasePriceTTC;
      const quantityCoffret = quantityProduct;
      addProductCoffretToCart(productId, quantityCoffret, priceCoffret);
    }
  };

  if (status === "loading") {
    return (
      <div className="loading-container" style={{ height: "100vh" }}>
        <div className="row w-100">
          <div className="col-md-12 justify-content-center align-content-center">
            <img className="w-25" src={imgload} alt="Loading" />
          </div>
          <div className="col-md-12 ">
            <p className="text-center ">
              <HashLoader
                className="m-auto"
                size={50}
                color={"#cf9a52"}
                loading={status === "loading"}
              />
            </p>
          </div>
        </div>
      </div>
    );
  } else if (status === "succeeded") {
    const {
      title,
      description,
      subtitle,
      label,
      subdescription,
      howToUse,
      composition,
      categorie,
      productImages = [],
    } = product;

    const translation =
      product.translates?.find((t) => t.language.id === languageId) || {};

    return (
      <>
        <HeaderUpdate />
        <ProgressBar />
        <section className="container container-detail">
          <div className="container-fluid mt-4">
            <div className="row w-auto align-items-start">
              <div className="col-md-5 m-auto d-flex align-items-center justify-content-center">
                <div
                  id="myCarousel"
                  className="row myCarousel-detail-product h-auto justify-content-center align-items-center g-2 carousel slide"
                  data-bs-ride="carousel"
                  align="center"
                >
                  <div
                    className="carousel-inner"
                    style={{
                      ...(product.categorie &&
                        product.categorie[0]?.title === "Miel" && {
                          border: "1px solid rgb(0, 0, 0, .1)",
                          borderRadius: "5%",
                        }),
                      zIndex: "3",
                    }}
                  >
                    {productImages.map((slidimage, index) => (
                      <div
                        key={index}
                        className={`carousel-item ${
                          index === 0 ? "active" : ""
                        }`}
                      >
                        <img
                          src={`${PMultiImgUrl}/${slidimage?.image}`}
                          className="img-fluid m-auto"
                          alt={slidimage.title}
                        />
                      </div>
                    ))}
                  </div>
                  <ol
                    className="carousel-indicators list-inline"
                    style={{ top: "50%", transform: "translateY(-20%)" }}
                  >
                    {productImages.map((slidimage, index) => (
                      <li
                        key={index}
                        className={`list-inline-item ${
                          index === 0 ? "active" : ""
                        } pt-0 mt-0`}
                        style={{ opacity: "1" }}
                      >
                        <a
                          id="carousel-selector-0"
                          className="selected pt-0 mt-0"
                          data-bs-slide-to={index}
                          data-bs-target="#myCarousel"
                        >
                          <img
                            style={{
                              border: "1px solid rgb(0, 0, 0, .1) ",
                              borderRadius: "5%",
                            }}
                            src={`${PMultiImgUrl}/${slidimage?.image}`}
                            className="img-fluid rounded pt-0 mt-0"
                            alt={slidimage?.title}
                          />
                        </a>
                      </li>
                    ))}
                  </ol>
                </div>
              </div>
              <div
                className="col-md-7 d-grid align-items-center justify-content-center content-detail"
                style={{ marginTop: "0px !important" }}
              >
                <div className="col-md-12 d-grid align-items-center">
                  <h1 className="text-center title">
                    {i18n.language === "fr"
                      ? title
                      : translation.title || title}
                  </h1>
                  <p
                    className="text-center mt-2 para-1"
                    style={{ fontFamily: "monatge", fontSize: "15pt" }}
                  >
                    {i18n.language === "fr"
                      ? subtitle
                      : translation.subtitle || subtitle}
                  </p>
                  <img
                    src={logodetail}
                    className="img-fluid w-25 m-auto img-logo"
                    alt="Detail Logo"
                  />
                  <h2 className="text-center" style={{ fontFamily: "monatge" }}>
                    {i18n.language === "fr"
                      ? label
                      : translation.label || label}
                  </h2>
                </div>
                <div className="col-md-12 d-grid align-items-center">
                  <p
                    className="text-left para-2 w-100"
                    style={{ fontFamily: "Montserrat" }}
                  >
                    {i18n.language === "fr"
                      ? subdescription
                      : translation.supdescription || subdescription}
                  </p>
                  <h1
                    className="text-left price-h1"
                    id="price"
                    style={{
                      fontFamily: "Montserrat-Regular",
                      fontSize: "30px",
                    }}
                  >
                    {price.toFixed(2) + "€"}
                  </h1>

                  {selectedVariant && (
                    <span>
                      Prix au kilo : {selectedVariant.sellingPrice.toFixed(2)} €{" "}
                    </span>
                  )}
                </div>
                <div className="col-md-12 d-grid align-items-center">
                  <div className="col d-flex justify-content-start ps-0">
                    <div
                      className="btn-group ms-0 ps-0 w-75 container-checkbox"
                      role="group"
                      aria-label="Basic radio toggle button group"
                    >
                      {product.categorie &&
                        product.categorie[0].title === "Miel" &&
                        product.variant &&
                        product.variant
                          .slice()
                          .sort((a, b) => parseInt(a.label) - parseInt(b.label))
                          .map((variant) => (
                            <React.Fragment key={variant.id}>
                              <input
                                type="radio"
                                className="btn-check mt-2 ms-1 rounded ps-0 pe-0"
                                name="price"
                                id={`btnradio${variant.id}`}
                                autoComplete="off"
                                value={variant.label} // Use variant.label as value
                                checked={price === variant.purchasePriceTTC}
                                onChange={handleRadioChange}
                              />
                              <label
                                className="btn btn-checkbox mt-2 ms-0 rounded ps-0 pe-0"
                                htmlFor={`btnradio${variant.id}`}
                              >
                                {variant.label}g
                              </label>
                            </React.Fragment>
                          ))}
                    </div>
                  </div>
                </div>
                <div className="col-md-12 d-flex input-box mt-4">
                  <button
                    type="button"
                    className="btn btn-minus"
                    onClick={() => handleButtonClick("decrement")}
                    style={{ boxShadow: "none", borderRadius: "10px" }}
                  >
                    -
                  </button>

                  <input
                    type="number"
                    min="1"
                    value={quantityProduct}
                    readOnly
                    style={{
                      width: "50px",
                      height: "50px",
                      border: "1px solid #cf9a52",
                      fontSize: "15px",
                      textAlign: "center",
                      borderRadius: "10%",
                    }}
                  />

                  <button
                    type="button"
                    className="btn btn-plus"
                    onClick={() => handleButtonClick("increment")}
                    style={{ boxShadow: "none", borderRadius: "10px" }}
                  >
                    +
                  </button>

                  <button
                    type="button"
                    className="add-to-cart"
                    onClick={
                      categorie[0].title === "Miel"
                        ? handleAddProductToCart
                        : handleAddCoffretToCart
                    }
                  >
                    {t("compostion-category.shopBtn")}
                  </button>
                </div>
                <p
                  className="text text-black-50 ms-4 mt-5"
                  style={{ fontSize: "14px" }}
                ></p>
              </div>
            </div>
          </div>
        </section>

        <Description
          description={
            i18n.language === "fr"
              ? description
              : translation.description || description
          }
          howToUse={
            i18n.language === "fr" ? howToUse : translation.conseil || howToUse
          }
          composition={
            i18n.language === "fr"
              ? composition
              : translation.composition || composition
          }
        />
        <Card
          title="Vous aimerez aussi"
          category={product.categorie[0].title}
        />
        <Footer />
      </>
    );
  } else {
    return !error ? (
      <div className="text-center">No products found</div>
    ) : (
      <div className="text-center">{error}</div>
    );
  }
};

export default Ditail;
