import React, { useEffect, useState } from 'react';
import PropagateLoader from 'react-spinners/PropagateLoader';
import { useCart } from "../../context/ShoppingCartContext";
import { packImgUrl, apiUrl } from './../../url/UrlHelpes';
import axios from "axios";
import FormatCurrency from "../../function/FormatCurrency";

const CheckoutCartitmProduct = ({ index, composition }) => {
    const { removepackFromCart } = useCart();
    const [pack, setPack] = useState(null);
    const [productCompositionOne, setProductCompositionOne] = useState(null);
    const [productCompositionTwo, setProductCompositionTwo] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPackAndProducts = async () => {
            try {
                // Fetch pack data
                const packResponse = await axios.get(`${apiUrl}/packs/${composition.packID}`);
                setPack(packResponse.data);

                // Fetch product data for itemProduct1
                if (composition.Products[0]?.productId) {
                    const productOneResponse = await axios.get(`${apiUrl}/products/${composition.Products[0].productId}`);
                    setProductCompositionOne(productOneResponse.data);
                }

                // Fetch product data for itemProduct2 if it exists
                if (composition.Products.length > 1 && composition.Products[1]?.productId) {
                    const productTwoResponse = await axios.get(`${apiUrl}/products/${composition.Products[1].productId}`);
                    setProductCompositionTwo(productTwoResponse.data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchPackAndProducts();
    }, [composition.packID, composition.Products]);

    if (loading) {
        return (
            <div className="text-center">
                <p className='pt-5 pb-5'>
                    <PropagateLoader className='' size={5} color={"#cf9a52"} />
                </p>
            </div>
        );
    }

    if (!pack) return null;

    const quantityProduct1 = composition.Products[0]?.quantity || 0;
    const quantityProduct2 = composition.Products[1]?.quantity || 0;

    const getVariantPrice = (product) => {
        const variant130 = product?.variant?.find((v) => v.label === "130");
        return variant130 ? parseFloat(variant130.purchasePriceTTC) : 0;
    };

    const totalPriceItem1 = getVariantPrice(productCompositionOne) * quantityProduct1;
    const totalPriceItem2 = getVariantPrice(productCompositionTwo) * quantityProduct2;

    return (
        <div key={pack.id} className="row border-top border-bottom">
            <div className="row main align-items-center">
                <div className="col-2 box-img">
                    <img className="img-fluid" src={`${packImgUrl}/${pack.image}`} alt={pack.title} />
                </div>
                <div className="col">
                    <div className="row text-muted" style={{ color: '#00000080' }}>{pack.title}</div>
                    <div className="row" style={{ color: '#00000080' }}>
                        {
                            composition.Products.length > 1
                                ? `${productCompositionOne?.title} + ${productCompositionTwo?.title}`
                                : productCompositionOne?.title
                        }
                    </div>
                </div>
                <div className="col"></div>
                <div className="col">
                    <span className="price-panier">
                        {FormatCurrency(totalPriceItem1 + totalPriceItem2)}
                    </span>
                    <span className="close">
                        <a href="#" className='text-decoration-none' onClick={() => removepackFromCart(index)}>
                            &#10005;
                        </a>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default CheckoutCartitmProduct;
