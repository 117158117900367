import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {apiUrl } from './../../url/UrlHelpes'

export const fetchPackById = createAsyncThunk(
    "pack/fetchPackById",
    async (packId) => {
      const response = await fetch(
        `${apiUrl}/packs/${packId}`
      );
      const data = await response.json();
      return data;
    }
  )

  const initialState = {
    pack: {},
    status: null,
  };
  

  const PackByIdSlice = createSlice({
    name: "pack",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchPackById.pending, (state) => {
          state.status = "loading";
        })
        .addCase(fetchPackById.fulfilled, (state, { payload: pack }) => {
          state.status = "succeeded";
          state.pack = pack;
        })
        .addCase(fetchPackById.rejected, (state, action) => {
          state.status = "failed";
          state.error = action.error.message;
        });
    },
  })

  export default PackByIdSlice.reducer