import React, { useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import { Button, CircularProgress, Box, Typography } from '@mui/material';

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setMessage(null);
  
    if (!stripe || !elements) {
      setLoading(false);
      return;
    }
  
    try {
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/success`, // Ensure this URL matches your success route
        },
      });
  
      if (error) {
        setMessage(error.message);
      } else {
        // You may not need this line if `return_url` is handled correctly.
        setMessage('Payment successful!');
        // navigate('/success'); // Consider removing this line if using `return_url`
      }
    } catch (error) {
      setMessage(error.message);
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <Box 
      component="form" 
      id="payment-form" 
      onSubmit={handleSubmit} 
      sx={styles.form}
    >
      <PaymentElement id="payment-element" />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        sx={styles.button}
        disabled={loading || !stripe || !elements}
      >
        {loading ? <CircularProgress size={24} /> : 'Pay now'}
      </Button>
      {message && (
        <Typography variant="body2" sx={styles.message}>
          {message}
        </Typography>
      )}
    </Box>
  );
};

const styles = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '70vh',
    padding: 2,
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: 1,
    backgroundColor: '#fff',
  },
  button: {
    marginTop: 2,
    padding: 1,
    fontSize: 16,
  },
  message: {
    marginTop: 2,
    color: 'error.main',
  },
};

export default CheckoutForm;
