import React, { createContext, useState, useContext , useEffect } from 'react';
import ShoppingCart from "../components/shopping/ShoppingCart";
import MessageSuccess from './../function/addMessageSuccess';

const CartContext = createContext({
  cartItems: [],
  setCartItems: () => {},
  addProductToCart: () => {},
  removeProductFromCart: () => {},
  openCart : () => {},
  closeCart : () => {},
  cartQuantity: 0,
  increaseCartQuantity: () => {}, 
  decreaseCartQuantity: () => {}
},);

export const ShoppingCartContext = ({ children }) => {
  const [cartItems, setCartItems] = useState(() => {
    const storedItems = localStorage.getItem("frontend");
    return storedItems ? JSON.parse(storedItems) : [[], [], []];
  });
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    localStorage.setItem("frontend", JSON.stringify(cartItems));
  }, [cartItems]);


  const openCart = () => { setIsOpen(true)};
  const closeCart = () => { setIsOpen(false) };


  const cartQuantity =(
            cartItems[0].reduce((total, item) => total + (item.quantityPoduct || 0), 0) +
            cartItems[1].reduce((total, item) => total + (item.quantityCoffret || 0), 0) +
            cartItems[2].reduce((total, item) => total + (1), 0)
      )
  const addProductToCart = (productId, quantityPoduct, categoryId, categoryName, categoryPricePoroduct) => {
    // Check if product already exists in cart
    const existingProductIndex = cartItems[0].findIndex(item => item.productId === productId && item.categoryId === categoryId);

    if (existingProductIndex !== -1) {
        // Update quantity of existing product and category
        const updatedCartItems = [...cartItems];
        updatedCartItems[0][existingProductIndex].quantityPoduct += quantityPoduct;
        setCartItems(updatedCartItems);
        MessageSuccess('Produit modifie avec succes')
    } else {
        // Add new product to cart
        const newItem = { productId, quantityPoduct, categoryId, categoryName, categoryPricePoroduct };
        setCartItems([[...cartItems[0], newItem], cartItems[1], cartItems[2]]);
        MessageSuccess('Produit ajoute avec succes')
    }
};

   // Implement `increaseCartQuantity` function:
   const increaseCartQuantity = (productId , categoryId) => {
    const existingProductIndex = cartItems[0].findIndex((item) => item.productId === productId && item.categoryId === categoryId);
    if (existingProductIndex !== -1) {
      const updatedCartItems = [...cartItems];
      updatedCartItems[0][existingProductIndex].quantityPoduct++;
      setCartItems(updatedCartItems);
    }
  };

  // Implement `decreaseCartQuantity` function:
  const decreaseCartQuantity = (productId, categoryId) => {
    const existingProductIndex = cartItems[0].findIndex((item) => item.productId === productId && item.categoryId === categoryId);
    if (existingProductIndex !== -1) {
      const updatedCartItems = [...cartItems];
      updatedCartItems[0][existingProductIndex].quantityPoduct--;
      if (updatedCartItems[0][existingProductIndex].quantityPoduct === 0) {
        updatedCartItems.splice(existingProductIndex, 1);
      }
      setCartItems(updatedCartItems);
    }
  };

  // Implement `removeProductFromCart` function

  const removeProductFromCart = (productId, categoryId) => {
    const updatedCartItems = [cartItems[0].filter(item => !(item.productId === productId && item.categoryId === categoryId)), cartItems[1], cartItems[2]];
    setCartItems(updatedCartItems);
  };

  
/************************************************************************************************ */
const addProductCoffretToCart = (productId, quantityCoffret ,priceCoffret) => {
  // Check if product already exists in cart
  const existingProductIndex = cartItems[1].findIndex(item => item.productId === productId);

  if (existingProductIndex !== -1) {
    // Update quantity of existing product and category
    const updatedCartItems = [...cartItems];
    updatedCartItems[1][existingProductIndex].quantityCoffret += quantityCoffret;
    setCartItems([cartItems[0], updatedCartItems[1], cartItems[2]]);
    MessageSuccess('Produit modifie avec succes')
  } else {
    // Add new product to cart
    const newItem = { productId, quantityCoffret ,priceCoffret};
    setCartItems([cartItems[0], [...cartItems[1], newItem], cartItems[2]]);
    MessageSuccess('Produit ajoute avec succes')

  }
};

// Implement `increaseCartQuantity` function
const increaseCartQuantityCoffret = (productId) => {
  const existingProductIndex = cartItems[1].findIndex((item) => item.productId === productId );
  if (existingProductIndex !== -1) {
    const updatedCartItems = [...cartItems];
    updatedCartItems[1][existingProductIndex].quantityCoffret++;
    setCartItems(updatedCartItems);
  }
};


// Implement `decreaseCartQuantity` function
const decreaseCartQuantityCoffret = (productId) => {
  const existingProductIndex = cartItems[1].findIndex((item) => item.productId === productId);
  if (existingProductIndex !== -1) {
    const updatedCartItems = [...cartItems];
    updatedCartItems[1][existingProductIndex].quantityCoffret--;
    if (updatedCartItems[1][existingProductIndex].quantityCoffret === 0) {
      updatedCartItems.splice(existingProductIndex, 1);
    }
    setCartItems(updatedCartItems);
  }
}

const removeCoffretFromCart = (productId) => {
  const updatedCartItems = [cartItems[0], cartItems[1].filter(item => !(item.productId === productId)), cartItems[2]];
  setCartItems(updatedCartItems);
  MessageSuccess('Produit supprime avec succes')
};

const addPackProductToCart = (packProducts) => {
  const updatedCartItems = [cartItems[0], cartItems[1], [...cartItems[2], ...packProducts]];
  setCartItems(updatedCartItems);
  MessageSuccess('Produit ajoute avec succes')
};

// Implement `removePackProductToCart` function
const removepackFromCart = (indexToRemove) => {
  const updatedCartItems = [cartItems[0],cartItems[1],cartItems[2].filter((_, index) => index !== indexToRemove)];
  setCartItems(updatedCartItems);
  MessageSuccess('Produit supprime avec succes');
};

  return (
    <CartContext.Provider
     value={{ 
            cartItems, 
            setCartItems, 
            addProductToCart,
            addProductCoffretToCart,
            removeProductFromCart , 
            removeCoffretFromCart,
            openCart, 
            closeCart ,
            cartQuantity,
            increaseCartQuantity,
            decreaseCartQuantity,
            increaseCartQuantityCoffret,
            decreaseCartQuantityCoffret,
            addPackProductToCart,
            removepackFromCart
        }}>
      {children}
      <ShoppingCart isOpen={isOpen} />
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);