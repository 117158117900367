import { createAsyncThunk , createSlice } from "@reduxjs/toolkit";
import {apiUrl } from './../../url/UrlHelpes'
export const fetchProductCoffretByIdCart = createAsyncThunk(
    "productCoffret/fetchProductCoffretByIdCart",
    async (productId) => {
        const response = await fetch(
            `${apiUrl}/products/${productId}`
        );
        const data = await response.json();
        return data;
    }
);

const initialState ={
    productCoffret: {},
    status: null,
}

const ProductCoffretByIdCartSlice = createSlice ({
    name: 'productCoffret',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(fetchProductCoffretByIdCart.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchProductCoffretByIdCart.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.productCoffret = action.payload;
            })
            .addCase(fetchProductCoffretByIdCart.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            });
    },
})

export default ProductCoffretByIdCartSlice.reducer 