import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {apiUrl } from './../../url/UrlHelpes'
export const fetchPack = createAsyncThunk(
  "packs/fetchPack",
  async () => {
    const response = await fetch(`${apiUrl}/liste-pack`);
    const data =  response.json();
    return data;
  }
);

const initialState = {
  packs: [],
  status: null,
};

const PackSlice = createSlice({
  name: "packs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPack.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPack.fulfilled, (state, { payload: packs }) => {
        state.status = "succeeded";
        state.packs = packs;
      })
      .addCase(fetchPack.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

// Action creators are generated for each case reducer function
// export const { increment, decrement, incrementByAmount } = counterSlice.actions

export default PackSlice.reducer;
