import React, { useState } from "react";
import HeaderUpdate from "../../../layouts/Header/HeaderUpdate";
import Footer from "../../../layouts/footer/footer";
import {  useParams } from "react-router-dom";
import ProgressBar from "../../../views/ProgressBar ";
import "./../login/LoginForm"; // Reusing the same styles
import { useTranslation } from "react-i18next";

const ResetPassword = () => {

  const [password, setPassword] = useState("");
  const { t } = useTranslation();
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const { token } = useParams();
  const apiUrl = "https://api.miel-montet.com";

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    const formData = new FormData();
    formData.append("password", password);

    try {
      const response = await fetch(`${apiUrl}/reset_password/${token}`, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        setSuccessMessage(data.message);
        setError("");
        window.location.href = "/login"; // Redirect to login page after successful password reset
      } else {
        const data = await response.json();
        setError(data.message || "An error occurred. Please try again.");
        setSuccessMessage("");
      }
    } catch (error) {
      setError("An error occurred. Please try again.");
      setSuccessMessage("");
    }
  };

  return (
    <>
      <HeaderUpdate />
      <ProgressBar />
      <div className="container pt-5" id="login-frm">
        <div className="row justify-content-center">
          <form className="login-form logi-frm" onSubmit={handleSubmit}>
            <div className="col-12 mb-2">
              <div className="input-box">
                <label htmlFor="password" className="form-label">
                {t("Auth.Nouveaumotdepasse")}<span className="clr-red">*</span>
                </label>
                <input
                  type="password"
                  name="password"
                  value={password}
                  onChange={handlePasswordChange}
                />
              </div>
            </div>
            <div className="col-12 mt-3">
              <div className="input-box">
                <label htmlFor="confirmPassword" className="form-label">
                {t("Auth.Confirmerlemotdepasse")}<span className="clr-red">*</span>
                </label>
                <input
                  type="password"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                />
                {error && (
                  <div className="error-message error-sty">{error}</div>
                )}
                {successMessage && (
                  <div className="success-message">{successMessage}</div>
                )}
              </div>
            </div>
            <div className="col-12 mt-4">
              <button
                type="submit"
                className="btn submit-btn fr-btn-rest"
                style={{ width: "40%" }}
              >
                {t("Auth.Réinitialiserlemotdepasse")}
              </button>
            </div>
            <hr className="hr-tag" />
          </form>
        </div>
      </div>
      <br />
      <br />
      <Footer />
    </>
  );
};

export default ResetPassword;
