import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import HeaderUpdate from "../../../layouts/Header/HeaderUpdate";
import Footer from "../../../layouts/footer/footer";
import { apiUrl } from "../../../url/UrlHelpes.jsx";
import { Link, useNavigate } from "react-router-dom";
import ProgressBar from "../../../views/ProgressBar .jsx";
import { useTranslation } from "react-i18next";

import {
  registerUserStart,
  registerUserSuccess,
  registerUserFailure,
} from "../../../features/Auth/userSlice.js";

const RegisterForm = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("register");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    
  } = useForm();

  const onSubmit = async (data) => {
    dispatch(registerUserStart());
    const formData = new FormData();
    // Append form values to formData
    formData.append("lastName", data.lastName);
    formData.append("firstName", data.firstName);
    formData.append("telephone", data.telephone);
    formData.append("email", data.email);
    formData.append("password", data.password);

    try {
      const response = await fetch(`${apiUrl}/user-register`, {
        method: "POST",
        body: formData,
      });
      const responseData = await response.json();
      dispatch(registerUserSuccess(responseData));
      // Redirect to the login page after successful registration
      navigate("/login");
    } catch (error) {
      dispatch(registerUserFailure(error.message));
      // Handle error, display error message
    }
  };

  return (
    <>
      <HeaderUpdate />
      <ProgressBar />

      <div className="container pt-5" id="login-frm">
        <div className="row align-items-center">
          <div className="col-md-12 col-lg-12 mb-3 text-center">
            <h2 className="fw-bold fs-1 mt-5">
              <div
                className="d-flex justify-content-center fr-mobile"
                style={{ fontSize: "30px", fontFamily: "Montserrat-Regular" }}
              >
                <Link
                  to="/login"
                  className="nav-link sign-in-up"
                  style={{
                    color: activeTab === "login" ? "#d59a48" : "#999",
                  }}
                  onClick={() => handleTabClick("login")}
                >
                  {t("Auth.login")} /
                </Link>
                <p></p>
                <p className="mx-2"></p>
                <Link
                  to="/register"
                  className="nav-link sign-in-up"
                  style={{
                    color: activeTab === "register" ? "#d59a48" : "#999",
                  }}
                  onClick={() => handleTabClick("register")}
                >
                  {t("Auth.register")}
                </Link>
              </div>
            </h2>
          </div>
        </div>
        <div className="row justify-content-center">
          <form
            className="login-form logi-frm"
            onSubmit={handleSubmit(onSubmit)}
          >
            {/* Fields for last name, first name, telephone, email, and password */}
            <div className="col-12 mb-2">
              <div className="input-box">
                <label htmlFor="nom" className="form-label">
                {t("Auth.Nom")}<span className="clr-red">*</span>
                </label>
                <input
                  type="text"
                  {...register("lastName", { required: true })}
                />
                {errors.lastName && (
                  <span className="error error-sty">
                    Veuillez saisir votre nom
                  </span>
                )}
              </div>
            </div>
            <div className="col-12 mb-2">
              <div className="input-box">
                <label htmlFor="prenom" className="form-label">
                {t("Auth.Prénom")}<span className="clr-red">*</span>
                </label>
                <input
                  type="text"
                  {...register("firstName", { required: true })}
                />
                {errors.firstName && (
                  <span className="error error-sty">
                    Veuillez saisir votre prenom
                  </span>
                )}
              </div>
            </div>
            <div className="col-12 mb-2">
              <div className="input-box">
                <label htmlFor="telephone" className="form-label">
                {t("Auth.telephone")}<span className="clr-red">*</span>
                </label>
                <input
                  type="text"
                  {...register("telephone", { required: true })}
                />
                {errors.telephone && (
                  <span className="error error-sty">
                    Veuillez saisir votre numéro de telephone
                  </span>
                )}
              </div>
            </div>
            <div className="col-12 mb-2">
              <div className="input-box">
                <label htmlFor="email" className="form-label">
                  Email<span className="clr-red">*</span>
                </label>
                <input
                  type="email"
                  {...register("email", { required: true })}
                />
                {errors.email && (
                  <span className="error error-sty">
                    Veuillez saisir votre email
                  </span>
                )}
              </div>
            </div>

            <div className="col-12 mt-3">
              <div className="input-box">
                <label htmlFor="password" className="form-label">
                {t("Auth.Motdepasse")}<span className="clr-red">*</span>
                </label>
                <input
                  type="password"
                  {...register("password", { required: true })}
                />
                {errors.password && (
                  <span className="error error-sty">
                    Veuillez saisir votre mot de passe
                  </span>
                )}
              </div>
            </div>
            <div className="col-12 mt-4">
              <button type="submit" className="btn submit-btn">
              {t("Auth.Senregistrer")}
              </button>
            </div>
            {/* <hr className="hr-tag" />
            <div className="social-login-container">
              <button type="button" className="google-btn login-icon">
                <div className="social-icon google-icon">
                  <img src={googleIcon} alt="Google Logo" />
                </div>
                <div className="social-text">Continue Avec Google</div>
              </button>
            </div>
            <p className="social-login-container social-text">Ou</p>
            <div className="social-login-container">
              {" "}
              <button type="button" className="facebook-btn login-icon">
                <div className="social-icon facebook-icon">
                  <img src={facebookIcon} alt="Facebook Logo" />
                </div>
                <div className="social-text">Continue Avec Facebook</div>
              </button>
            </div> */}
          </form>
        </div>
      </div>
      <br />
      <br />
      <Footer />
    </>
  );
};

export default RegisterForm;
