import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { decodeHtmlTags } from "@mbs-dev/react-helpers";
import PropagateLoader from "react-spinners/PropagateLoader";
import imgagelogo from "./../../assets/images/logo-page-detail.png";
import Footer from "../../layouts/footer/footer";
import HeaderUpdate from "../../layouts/Header/HeaderUpdate";
import { useTranslation } from "react-i18next";
import ProgressBar from "../../views/ProgressBar ";
import { Link as ScrollLink } from "react-scroll";
import { fetchBlogs } from "../../features/blog/BlogSlice";
import { useDispatch, useSelector } from "react-redux";
import { blogImgUrl } from "./../../url/UrlHelpes";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "./../../assets/css/Nos-Actus/listeactus.css";

const Listeactus = () => {
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString("fr-FR", options); // Corrected date format
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(4);
  const { t } = useTranslation();
  const [expandedCards, setExpandedCards] = useState([]);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const dispatch = useDispatch();
  const { blogs, status } = useSelector((state) => state.blogs);

  useEffect(() => {
    dispatch(fetchBlogs()); // Corrected function name
  }, [dispatch]);

  const blog = blogs.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const toggleDescription = (cardId) => {
    setExpandedCards((prevExpandedCards) => {
      if (prevExpandedCards.includes(cardId)) {
        return prevExpandedCards.filter((id) => id !== cardId);
      } else {
        return [...prevExpandedCards, cardId];
      }
    });
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleShowModal = (blog) => {
    setSelectedBlog(blog);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelectedBlog(null);
    setShowModal(false);
  };

  return (
    <>
      <HeaderUpdate />
      <ProgressBar />
      <h1
        className="main-actus text-center pt-3"
        id="actus"
        style={{ color: "#c49c63" }}
      >
        {t("header.ourNews")}
      </h1>
      <img
        className="logodetail mb-3"
        src={imgagelogo}
        alt=""
        style={{ display: "block", margin: "auto" }}
      />
      <div className="container card-container-1 pt-2 mb-5">
        {status === "loading" && (
          <div className="loading-container">
            <div className="loading-spinner"></div>
            <div
              className="loading-text"
              style={{ fontFamily: "Prata-Regular", fontSize: "18px" }}
            >
              <PropagateLoader
                className="mt-2 mb-2"
                size={17}
                color={"#cf9a52"}
              />
            </div>
          </div>
        )}

        {status === "rejected" && (
          <div>{t("message.récupérerlesdonnées")}</div>
        )}

        {status === "success" &&
          blog.map((card) => (
            <div key={card.id} className="card card-description">
              <img
                src={`${blogImgUrl}/${card?.image}`}
                className="card-img-top"
                alt={`Image ${card.id}`}
              />
              <div className="card-info ms-3 me-3">
                <h2 className="style-h2">{card.title}</h2>
                <p className="Medium">{formatDate(card.createdAt)}</p>
                <br />
                <p
                  className="style-p"
                  dangerouslySetInnerHTML={{
                    __html: card.description
                      ? decodeHtmlTags(
                          expandedCards.includes(card.id)
                            ? card.description
                            : `${card.description
                                .split("\n")
                                .slice(0, 6)
                                .join("\n")}${
                                card.description.length > 200 ? ".." : ""
                              }`
                        )
                      : "",
                  }}
                />
                <br />
                <Link
                    to={`/detail-actus/${card.id}`}
                    className="lien Medium"
                    onClick={scrollToTop}
                  >
                    {t("listemiels.Lireplus")}
                  </Link>
              </div>
            </div>
          ))}
      </div>

      {blogs.length > itemsPerPage && (
        <ul className="pagination pagination-list justify-content-center">
          {Array.from({ length: Math.ceil(blogs.length / itemsPerPage) }).map(
            (_, index) => (
              <li
                key={index}
                className={`page-item ${
                  currentPage === index + 1 ? "active" : ""
                }`}
              >
                <ScrollLink
                  key={index}
                  onClick={() => paginate(index + 1)}
                  to="actus"
                  smooth={true}
                  duration={1000}
                  className="page-link pagination-link"
                >
                  {index + 1}
                </ScrollLink>
              </li>
            )
          )}
        </ul>
      )}

      <Footer />

      {/* <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontFamily: "SourceSansPro-Regular, serif" }}
          >
            {selectedBlog?.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={`${blogImgUrl}/${selectedBlog?.image}`}
            className="img-fluid w-25"
            alt=""
          />
          <h4 className="m-3" style={{ fontFamily: "prata, serif" }}>
            {selectedBlog?.title}
          </h4>
          <p
            className="text-justify m-3"
            style={{ fontFamily: "montserrat, serif" }}
            dangerouslySetInnerHTML={{
              __html: selectedBlog?.description
                ? decodeHtmlTags(selectedBlog.description)
                : "",
            }}
          ></p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ width: "30%", backgroundColor: "#cf9a52", border: "none" }}
            onClick={handleCloseModal}
          >
            FERMER
          </Button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
};

export default Listeactus;
