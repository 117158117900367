import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { apiUrl } from './../../url/UrlHelpes';

// API base URLs
const apiUrlFr = `${apiUrl}/products.json`; // For French
const apiUrlEn = `${apiUrl}/products.json?translates.language.id=2`; // For English

// Fetch products action
export const fetchProducts = createAsyncThunk('products/fetchProducts', async (languageId = 1) => {
  const url = languageId === 1 ? apiUrlFr : apiUrlEn; // Determine URL based on languageId
  const response = await axios.get(url);
  return response.data;
});

const productsSlice = createSlice({
  name: 'products',
  initialState: {
    products: [],
    allProducts: [], // Add allProducts to store all products
    status: 'idle',
    error: null,
  },
  reducers: {
    filterProductsByCategory: (state, action) => {
      const category = action.payload;
      console.log('Filtering products by category:', category); // Debugging log
      if (category === "Tous Produits") {
        state.products = [...state.allProducts];
      } else {
        state.products = state.allProducts.filter(product => {
          return product.categorie.some(cat => cat.title === category);
        });
      }
      console.log('Filtered products:', state.products); // Debugging log
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.products = action.payload;
        state.allProducts = action.payload; // Set allProducts when products are fetched
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { filterProductsByCategory } = productsSlice.actions;

export default productsSlice.reducer;
