import React, { useEffect } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import PropagateLoader from "react-spinners/PropagateLoader";
import { fetchProducts } from "../../../features/product/ProductsSlice";
import { productImgUrl } from "../../../url/UrlHelpes";
// import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./../../../assets/css/Home/Sliders/SliderNormal.css";

const SliderNormal = () => {
  const { t, i18n } = useTranslation();
  const data = useSelector((state) => state.products);
  const { status, error } = useSelector((state) => state.products);
  const dispatch = useDispatch();

  const languageId = i18n.language === 'fr' ? 1 : 2;

  useEffect(() => {
    dispatch(fetchProducts(languageId));
  }, [dispatch, languageId]);

  const ProductData = data.products.filter(
    (product) => product.categorie[0].title === "Coffrets"
  );

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="container-fluid m-auto">
      <div className="slider-container mt-4" style={{ margin: "auto", width: "80%" }}>
        <h3 className="text-center mb-5">{t("sliderNormal.title")}</h3>
        <Slider {...settings}>
          {status === "loading" ? (
            <div className="text-center">
              <p className="pt-5 pb-5">
                <PropagateLoader
                  className="mt-2 mb-2"
                  size={17}
                  color={"#cf9a52"}
                />
              </p>
            </div>
          ) : ProductData && ProductData.length > 0 ? (
            ProductData.map((card) => {
              const translation = card.translates.find(t => t.language.id === languageId) || {};
              return (
                <div key={card.id} className="col-md-12">
                  <div className="col-md-12 card-content-box">
                    <div className="row">
                      <div
                        className="col-sm-6 title-box"
                        style={{
                          height: "20%",
                          borderRadius: "7px 0px 0px 7px",
                        }}
                      >
                        <div className="row pt-3 mt-3">
                          <h6 style={{ fontSize: "24px" }}>
                            {i18n.language === 'fr' ? card.title.toLowerCase() : (translation.title?.toLowerCase() || card.title.toLowerCase())}
                          </h6>
                        </div>
                        <div className="row mt-1">
                          <p className="text-box">
                            {i18n.language === 'fr'
                              ? card.subtitle.length > 100
                                ? `${card.subtitle.substring(0, 90)}...`
                                : card.subtitle
                              : translation.subtitle?.length > 100
                                ? `${translation.subtitle.substring(0, 90)}...`
                                : translation.subtitle || card.subtitle
                            }
                          </p>
                        </div>
                        <div className="row d-flex align-items-center">
                          <Link
                            to={`/detail-miel/${card.id}`}
                            className="text-center pb-4"
                            style={{ color: "#9e9ca5", fontWeight: "bold" }}
                          >
                            {t("sliderNormal.link")}
                          </Link>
                        </div>
                      </div>
                      <img
                        src={`${productImgUrl}/${card.image}`}
                        className="image-slider img-fluid w-50 pe-0 ps-0"
                        alt={translation.title || card.title}
                        style={{ borderRadius: "0 7px 7px 0" }}
                      />
                    </div>
                  </div>
                </div>
              );
            })
          ) : !error ? (
            <div className="text-center">{t("message.noProductsFound")}</div>
          ) : (
            <div className="text-center">{error}</div>
          )}
        </Slider>
      </div>
    </section>
  );
};

export default SliderNormal;
