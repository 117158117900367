import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl } from './../../url/UrlHelpes';
import axios from 'axios';

export const fetchProductMielByIdCart = createAsyncThunk(
  "productMiel/fetchProductMielByIdCart",
  async (productId) => {
    const response = await axios.get(`${apiUrl}/products/${productId}`);
    return { productId, product: response.data };
  }
);

const initialState = {
  products: {},
  status: 'idle',
  error: null,
};
const productMielByIdCartSlice = createSlice({
  name: 'productMiel',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductMielByIdCart.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProductMielByIdCart.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.products[action.payload.productId] = action.payload.product;
      })
      .addCase(fetchProductMielByIdCart.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default productMielByIdCartSlice.reducer;
