import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {apiUrl } from './../../url/UrlHelpes'

export const fetchProductOneCompositionOneById = createAsyncThunk(
  "ProductOneCompositionOne/fetchProductOneCompositionOneById",
  async (productId) => {
    const response = await fetch(
      `${apiUrl}/products/${productId}`
    );
    const data = await response.json();
    return data;
  }
);


const initialState = {
    ProductOneCompositionOne: {},
    status: null,
  };
  
  const ProductOneCompositionOneByIdSlice = createSlice({
    name: "ProductOneCompositionOne",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchProductOneCompositionOneById.pending, (state) => {
          state.status = "loading";
        })
        .addCase(fetchProductOneCompositionOneById.fulfilled, (state, { payload: ProductOneCompositionOne }) => {
          state.status = "succeeded";
          state.ProductOneCompositionOne = ProductOneCompositionOne;
        })
        .addCase(fetchProductOneCompositionOneById.rejected, (state, action) => {
          state.status = "failed";
          state.error = action.error.message;
        });
    },
  });
  
  export default ProductOneCompositionOneByIdSlice.reducer;