import React from "react";
import { decodeHtmlTags } from '@mbs-dev/react-helpers'
import "./../../assets/css/PageDetail/liste.css";
import { useTranslation } from "react-i18next";

const Description = ({ description, composition, howToUse }) => {
  const decodedDescription = decodeHtmlTags(description);
  const decodedComposition = composition ? decodeHtmlTags(composition) : null;
  const decodedHowToUse = howToUse ? decodeHtmlTags(howToUse) : null;
  const { t } = useTranslation();

  return (
    <div className="container-fluid-liste " style={{ marginLeft:'10%'}}>
      <div className="row ms-2 editmobi">
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link nav-link-prata active fs-5"
              id="description-tab"
              data-bs-toggle="tab"
              data-bs-target="#description"
              type="button"
              role="tab"
              aria-controls="description"
              aria-selected="true"
            >
             {t("message.Description")}
            </button>
          </li>
          {composition &&
          <li className="nav-item" role="presentation">
            <button
              className="nav-link nav-link-prata fs-5"
              id="compositions-tab"
              data-bs-toggle="tab"
              data-bs-target="#compositions"
              type="button"
              role="tab"
              aria-controls="compositions"
              aria-selected="false"
            >
              {t("message.Compositions")}
            </button>
          </li>
          }
          {howToUse &&
          <li className="nav-item" role="presentation">
            <button
              className="nav-link nav-link-prata  fs-5"
              id="conseils-tab"
              data-bs-toggle="tab"
              data-bs-target="#conseils"
              type="button"
              role="tab"
              aria-controls="conseils"
              aria-selected="false"
            >
              {t("message.Conseils")}
            </button>
          </li>
          }
        </ul>
      </div>
      <br />

      <div className="row">
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active Mons "
            id="description"
            role="tabpanel"
            aria-labelledby="description-tab"
          >
              <p
              style={{textAlign: "justify"}}
                dangerouslySetInnerHTML={{
                  __html: decodedDescription
                }}
              ></p>
          </div>
          {composition &&
          <div
            className="tab-pane fade"
            id="compositions"
            role="tabpanel"
            aria-labelledby="compositions-tab"
          >
            <div className="col Mons">
            <p
                style={{ textAlign: "justify" }}
                dangerouslySetInnerHTML={{
                  __html: decodedComposition
                }}
              ></p>
              
            </div>
          </div>
          }
          {howToUse &&
          <div
            className="tab-pane fade"
            id="conseils"
            role="tabpanel"
            aria-labelledby="conseils-tab"
          >
            <div className="col Mons">
            <p
                style={{ textAlign: "justify" }}
                dangerouslySetInnerHTML={{
                  __html: decodedHowToUse
                }}
              ></p>
            </div>
          </div>
          }
        </div>
      </div>
    </div>
  );
};

export default Description;
