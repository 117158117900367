import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {apiUrl } from './../../url/UrlHelpes'

export const fetchVariantById = createAsyncThunk(
  "variants/fetchVariantById",
  async (variantId) => {
    const response = await fetch(
      `${apiUrl}/variants/${variantId}`
    );
    const data = await response.json();
    return data;
  }
);

const initialState = {
  variant: {},
  status: null,
};

const variantByIdSlice = createSlice({
  name: "variantById",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchVariantById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchVariantById.fulfilled, (state, { payload: variant }) => {
        state.status = "succeeded";
        state.variant = variant;
      })
      .addCase(fetchVariantById.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default variantByIdSlice.reducer;
