import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {apiUrl } from './../../url/UrlHelpes'
export const fetchMiels = createAsyncThunk("products/fetchMiels", async () => {
  const response = await axios.get(`${apiUrl}/products`);
  return response.data;
});

export const mielsSlice = createSlice({
  name: "miels",
  initialState: {
    miels: [],
    status: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMiels.fulfilled, (state, { payload }) => {
        state.miels = payload;
        state.status = "success";
      })
      .addCase(fetchMiels.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchMiels.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export default mielsSlice.reducer;
