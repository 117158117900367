import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl } from './../../url/UrlHelpes';

// API base URLs
const apiUrlFr = `${apiUrl}/products`; // Base URL for French
const apiUrlEn = `${apiUrl}/products`; // Base URL for English

export const fetchProductById = createAsyncThunk(
  "product/fetchProductById",
  async ({ productId, languageId = 1 }) => {
    const url = languageId === 1 
      ? `${apiUrlFr}/${productId}.json` 
      : `${apiUrlEn}/${productId}.json?translates.language.id=2`;
    const response = await fetch(url);
    const data = await response.json();
    return data;
  }
);

const initialState = {
  product: {},
  status: null,
  error: null,
};

const productByIdSlice = createSlice({
  name: "productById",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductById.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchProductById.fulfilled, (state, { payload: product }) => {
        state.status = "succeeded";
        state.product = product;
      })
      .addCase(fetchProductById.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default productByIdSlice.reducer;
