import React, { useEffect, useState } from "react";
import { Offcanvas, Stack } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useCart } from "../../context/ShoppingCartContext";
import FormatCurrency from "../../function/FormatCurrency";
import { apiUrl } from './../../url/UrlHelpes';
import CartItem1 from "./CartItems";
import CartItem2 from "./ItemsCofferts";
import CartItem3 from "./ItemsPackProduct";
import imgPanier from './../../assets/images/panier-vide.png';

const ShoppingCart = ({ isOpen }) => {
  const { t } = useTranslation();
  const { closeCart, cartItems } = useCart();
  const [loading, setLoading] = useState(true);
  const [productData, setProductData] = useState([]);
  const [productCompositionOne, setProductCompositionOne] = useState(null);
  const [productCompositionTwo, setProductCompositionTwo] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const promises = cartItems[2].flatMap(item =>
          item.Products.map(product =>
            axios.get(`${apiUrl}/products/${product.productId}`)
          )
        );

        const responses = await Promise.all(promises);
        setProductData(responses.map(response => response.data));

        responses.forEach((response, index) => {
          if (index === 0) {
            setProductCompositionOne(response.data);
          } else if (index === 1) {
            setProductCompositionTwo(response.data);
          }
        });
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [cartItems]);

  if (loading) {
    return <div>Loading...</div>;
  }

  // const getVariantPrice = (product) => {
  //   const variant = product?.variant?.find(v => v.label === "130");
  //   return variant ? parseFloat(variant.purchasePriceTTC) : 0;
  // };

  const getVariantPrice = (product) => {
    const variant130 = product?.variant?.find((v) => v.label === "130");
    return variant130 ? parseFloat(variant130.purchasePriceTTC) : 0;
};

  let totalPricePack = 0;

  cartItems[2].forEach(item => {
    let totalCartItem = 0;
    // const product1 = productData.find(p => p.id === item.Products[0]?.productId);
    // const product2 = item.Products.length > 1 ? productData.find(p => p.id === item.Products[1]?.productId) : null;

    if (item.Products.length > 1) {
      const quantityProduct1 = item.Products[0]?.quantity || 0;
      const quantityProduct2 = item.Products[1]?.quantity || 0;
      const totalPriceItemProduct1 = getVariantPrice(productCompositionOne) * quantityProduct1;
      const totalPriceItemProduct2 = getVariantPrice(productCompositionTwo) * quantityProduct2;
      totalCartItem = totalPriceItemProduct1 + totalPriceItemProduct2;
    } else {
      const quantityProduct1 = item.Products[0]?.quantity || 0;
      const totalPriceItemProduct1 = getVariantPrice(productCompositionOne) * quantityProduct1;
      totalCartItem = totalPriceItemProduct1;
    }

    totalPricePack += totalCartItem;
  });


  // Calculate total price
  const totalPrice = cartItems[0].reduce((total, item) => {
    return total + (item.categoryPricePoroduct || 0) * item.quantityPoduct;
  }, 0) + cartItems[1].reduce((total, item) => {
    return total + (item.priceCoffret || 0) * item.quantityCoffret;
  }, 0) + totalPricePack;


  return (
    <Offcanvas id="offcanvas-panier" show={isOpen} onHide={closeCart} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title style={{ color: '#cf9a52' }}>{t('cartShop.title')}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Stack gap={3}>
          {cartItems[0].length === 0 && cartItems[1].length === 0 && cartItems[2].length === 0 && (
            <>
              <img src={imgPanier} alt="Empty cart" className="w-50 m-auto" />
              <p className="w-100 text-center text-lead" style={{ color: '#c49c63' }}>
                Votre panier est actuellement vide.
              </p>
            </>
          )}
          {cartItems[0].map((item, index) => (
            <CartItem1 key={index} {...item} />
          ))}
          {cartItems[1].map((item, index) => (
            <CartItem2 key={index} {...item} />
          ))}
          {cartItems[2].map((composition, index) => (
            <CartItem3 key={index} index={index} composition={composition} />
          ))}
          <div className="ms-auto fw-bold fs-5" style={{ color: '#00000080' }}>
            Total {FormatCurrency(totalPrice)}
          </div>
          <a href="/page-panier" style={{ color: '#cf9a52' }}>{t('cartShop.link')}</a>
        </Stack>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default ShoppingCart;
