import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useLocation, useNavigate } from "react-router-dom";
import CheckoutForm from "./CheckoutForm";
import { CircularProgress, Container, Typography } from "@mui/material";
import { apiUrl } from "./../url/UrlHelpes";


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "pk_test_51PVEY9DvxXmBt3SpE5oqeLYRwTulAxs7XpUSZXuCSB33hrU8cry08RAeNsccBSd6HaiUkTznRqU84lmlwQrwiRN900FZFnm0C3");

const PaymentPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [clientSecret, setClientSecret] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!location.state) {
      navigate("/some-default-page");
      return;
    }

    const { totalPrice, orderId } = location.state;
    fetch(`${apiUrl}/create-payment-intent`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ amount: totalPrice * 100, order_id: orderId }),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('Network response was not ok');
        }
        return res.json();
      })
      .then((data) => {
        setClientSecret(data.clientSecret);
        setLoading(false);
      })
      .catch((error) => {
        // console.error("Error fetching client secret:", error);
        setError("An error occurred while processing your payment.");
        setLoading(false);
      });
  }, [location.state, navigate]);

  if (loading) {
    return (
      <Container style={styles.loadingContainer}>
        <CircularProgress />
        <Typography variant="body2" style={styles.loadingText}>
          Loading...
        </Typography>
      </Container>
    );
  }

  if (error) {
    return (
      <Container style={styles.errorContainer}>
        <Typography variant="body2" color="error">
          {error}
        </Typography>
      </Container>
    );
  }

  return (
    <Elements stripe={stripePromise} options={{ clientSecret }}>
      <Container style={styles.container} maxWidth="sm">
        <Typography variant="h4" style={styles.title}>
        Complétez votre paiement
        </Typography>
        <CheckoutForm />
      </Container>
    </Elements>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "60vh",
  },
  loadingContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
  loadingText: {
    marginTop: "20px",
  },
  errorContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
  title: {
    marginBottom: "20px",
  },
};

export default PaymentPage;
