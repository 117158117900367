import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {apiUrl } from './../../url/UrlHelpes'

export const fetchCategoryWeight = createAsyncThunk(
    "categoryWeight/fetchCategoryWeight",
    async () => {
      const response = await fetch(
        `${apiUrl}/category_weights.json`
      );
      const data = response.json();
      return data;
    }
)

const initialState = {
  categoryWeight: [],
  status: null,
};

const CategoryWeightSlice = createSlice({
  name: "categoryWeight",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategoryWeight.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        fetchCategoryWeight.fulfilled, (state, { payload: categoryWeight }) => {
          state.status = "succeeded";
          state.categoryWeight = categoryWeight;
        }
      )
      .addCase(fetchCategoryWeight.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
})

export default CategoryWeightSlice.reducer