// src/SuccessPage.js
import React from 'react';
import { Container, Typography } from '@mui/material';

const SuccessPage = () => {
  return (
    <Container style={styles.container}>
      <Typography variant="h4">Paiement réussi !</Typography>
      <Typography variant="body1">Merci pour votre paiement.</Typography>
    </Container>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
};

export default SuccessPage;