import React, { useEffect, useState } from "react";
import PropagateLoader from 'react-spinners/PropagateLoader';
import { useCart } from "../../context/ShoppingCartContext";
import FormatCurrency from "../../function/FormatCurrency";
import { productImgUrl, apiUrl } from './../../url/UrlHelpes';
import axios from 'axios';

const CheckoutCartitmProduct = ({
  productId,
  categoryId,
  quantityPoduct,
  categoryName,
  categoryPricePoroduct,
}) => {
  const { increaseCartQuantity, decreaseCartQuantity, removeProductFromCart } = useCart();
  const [productMiel, setProductMiel] = useState(null);
  const [translatedProduct, setTranslatedProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProduct = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${apiUrl}/products/${productId}`);
        setProductMiel(response.data);

        const translationResponse = await axios.get(`${apiUrl}/products/${productId}.json?translates.language.id=2`);
        setTranslatedProduct(translationResponse.data);

        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchProduct();
  }, [productId]);

  if (loading) return (
    <div className="text-center">
      <p className='pt-5 pb-5'>
        <PropagateLoader size={5} color={"#cf9a52"} />
      </p>
    </div>
  );

  if (error) return <div>Error: {error}</div>;

  return (
    <>
      {quantityPoduct > 0 && productMiel && (
        <div key={productMiel.id} className="row border-top border-bottom">
          <div className="row main align-items-center">
            <div className="col-2 box-img">
              <img
                className="img-fluid"
                src={`${productImgUrl}/${productMiel.image}`}
              />
            </div>
            <div className="col">
              <div className="row text-muted" style={{ color: "#00000080" }}>
                <div className="col-md-10">
                  {translatedProduct ? translatedProduct.title : productMiel.title}
                </div>
                {quantityPoduct > 1 && (
                  <div className="col-md-2" style={{ fontSize: "0.65rem" }}>
                    x{quantityPoduct}
                  </div>
                )}
              </div>
              <div className="row" style={{ color: "#00000080" }}>
                <span className="ms-2">{categoryName}g</span>
              </div>
            </div>
            <div className="col">
              <div className="row d-flex justify-content-center">
                <div className="col-3 d-flex justify-content-end pe-0">
                  {quantityPoduct > 1 && (
                    <button
                      type="button"
                      className="btn btn-minus"
                      onClick={() => decreaseCartQuantity(productId, categoryId)}
                    >
                      -
                    </button>
                  )}
                </div>

                <div className="col-6 d-flex justify-content-center ps-0 pe-0">
                  <input
                    type="text"
                    className="text-center input-panier-quantity"
                    min="1"
                    value={quantityPoduct}
                    readOnly
                  />
                </div>
                <div className="col-3 d-flex justify-content-start ps-0">
                  <button
                    type="button"
                      className="btn btn-plus"
                    onClick={() => increaseCartQuantity(productId, categoryId)}
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
            <div className="col">
              <span className="price-panier">
                {FormatCurrency(categoryPricePoroduct * quantityPoduct)}
              </span>
              <span className="close">
                <a
                  href="#"
                  className="text-decoration-none"
                  onClick={() => removeProductFromCart(productId, categoryId)}
                >
                  &#10005;
                </a>
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CheckoutCartitmProduct;
