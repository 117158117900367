import React, { useState , useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { packImgUrl } from "../../url/UrlHelpes";
import { useTranslation } from "react-i18next";
import HeaderUpdate from "../../layouts/Header/HeaderUpdate";
import PropagateLoader from 'react-spinners/PropagateLoader';
import "./../../assets/css/Voscompositions/CompositionCategory.css";
import logo from "../../assets/images/logo-page-detail.png";

import { useDispatch, useSelector } from 'react-redux';
import { fetchPack } from "../../features/pack/PackSlice"; 

import "../../assets/css/history.css";

const Coffretcat = () => {
  const [selectedCoffrets, setSelectedCoffrets] = useState([]);
  const [showButtonContainer, setShowButtonContainer] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
   const { packs, status, error }= useSelector((state) => state.packs);

  useEffect(() => {
    dispatch(fetchPack());

  }, [dispatch])

  const handleIconClick = (productId) => {

    let updatedSelectedCoffrets;
    if (selectedCoffrets.includes(productId)) {
      updatedSelectedCoffrets = [];
    } else {
      updatedSelectedCoffrets = [productId];
    }
    setSelectedCoffrets(updatedSelectedCoffrets);
    setShowButtonContainer(updatedSelectedCoffrets.length > 0);
  };

  const isCoffretSelected = (productId) => {
    return selectedCoffrets.includes(productId);
  };

  const handleContinueClick = () => {
    const route = `/liste-miles-composition/${selectedCoffrets.join(",")}`;
    navigate(route);
  };

  return (
    <>
      <HeaderUpdate />
      <section className="container-fluid container-fluid-histoire p-0 m-0" style={{background:"none"}}>
        <div className="container container-histoire">
          <div className="row w-100">
            <div className="col-md-12 w-100">
              <div className="col-md-12 ms-2">
                <h1 className="text-center">{t("compostion-category.sotitle")}</h1>
              </div>
              <div className="col-md-12 ms-2">
                <img
                    src={logo}
                    className="img-fluid  m-auto d-block history-img-logo "
                    alt="miel montet arabesque"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container  mt-4" id="block_coffrets-pack">
        <div className="row justify-content-center w-100">
          {
            status === "loading" ? (
              <div className="text-center">
                <p className='pt-5 pb-5'>
                  <PropagateLoader className='mt-2 mb-2'  size={17} color={"#cf9a52"}  />
                </p>
              </div>
          ) : packs && packs.length > 0 ? (

            packs.slice(0, 3).map((coffret) => (
              <div
                key={coffret.id}
                className="col-lg-3 justify-content-center align-content-center col-md-6 mt-3 compos-item"
              >
                <div className="card card-image h-100">
                  <img
                    src={`${packImgUrl}/${coffret?.image}`}
                    className="card-img-top clickable-image"
                    alt={coffret.title}
                    onClick={() => handleIconClick(coffret.id)}
                    style={{width:'300px', height:'300px'}}
                  />
                  <div className="card-body">
                    <p
                      className="card-title block__title"
                      style={{ fontFamily: "prata", fontSize: "20px" }}
                    >
                      {coffret.title}
                    </p>
  
                    <span
                      className={`composer__checkbox ${
                        isCoffretSelected(coffret.id) ? "active" : ""
                      }`}
                      onClick={() => handleIconClick(coffret.id)}
                    >
                      <span className="icon icon-check" aria-hidden="true"></span>
                    </span>
                  </div>
                </div>
              </div>
            ))
            
          ): (
            !error ? <div className="text-center">{t("message.Noproductsfound")}</div> : <div className="text-center">{error}</div>
          )
          }
        </div>

        {showButtonContainer && (
          <div className="button-container">
            <button
              className="btnconti"
              style={{ textDecoration: "none" , borderRadius:'7px' }}
              onClick={handleContinueClick}
            >
              {t("compostion-category.CONTINUER")}
            </button>
          </div>
        )}
        <br />
        <br />
        <br />
      </div>
    </>
  );
};

export default Coffretcat;
