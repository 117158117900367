import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { decodeHtmlTags } from "@mbs-dev/react-helpers";
import PropagateLoader from "react-spinners/PropagateLoader";
import { useTranslation } from "react-i18next";
import { fetchBlogs } from "../../features/blog/BlogSlice";
import { useDispatch, useSelector } from "react-redux";
import { blogImgUrl } from "./../../url/UrlHelpes";
import "../../assets/css/Home/cards.css";

const Cards = () => {
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString.createdAt).toLocaleDateString("fr-FR", options);
  };

  const dispatch = useDispatch();
  const { blogs, status, error } = useSelector((state) => state.blogs);

  useEffect(() => {
    dispatch(fetchBlogs());
  }, [dispatch]);

  const { t } = useTranslation();
  const sortedActus = [...blogs].sort(
    (a, b) => new Date(a.date) - new Date(b.date)
  );
  const firstFourActus = sortedActus.slice(0, 4);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const truncateDescription = (description) => {
    const lines = description.split('\n');
    if (lines.length > 6) {
      return lines.slice(0, 6).join('\n');
    } else {
      return description;
    }
  };

  return (
    <>
      <h3 className="text-center mb-5 actu">{t("message.Notreactualité")} </h3>
      <div className="container card-container-1  mt-5 pt-2 mb-5 ">
        {status === "loading" ? (
          <div className="text-center">
            <p className="pt-5 pb-5">
              <PropagateLoader
                className="mt-2 mb-2"
                size={17}
                color={"#cf9a52"}
              />
            </p>
          </div>
        ) : firstFourActus && firstFourActus.length > 0 ? (
          firstFourActus.map((card) => (
            <div key={card.id} className="card card-description">
              <img
                src={`${blogImgUrl}/${card?.image}`}
                className="card-img-top"
                alt={`Image ${card.id}`}
              />
              <div className="card-info ms-3 me-3">
                <h2 className="style-h2">{card.title}</h2>
                <p className="Medium">{formatDate(card)}</p>

                <p
                  className="style-p mb-0"
                  dangerouslySetInnerHTML={{
                    __html: decodeHtmlTags(truncateDescription(card.description)),
                  }}
                ></p>

                <div className="lire-b fixed-buttom">
                  <Link
                    to={`/detail-actus/${card.id}`}
                    className="lien Medium"
                    onClick={scrollToTop}
                  >
                    {t("listemiels.Lireplus")}
                  </Link>
                </div>
              </div>
            </div>
          ))
        ) : !error ? (
          <div className="text-center">{t("message.Nonewsfound")}</div>
        ) : (
          <div className="text-center">{error}</div>
        )}
      </div>
    </>
  );
};

export default Cards;
