import React from "react";
import ReactDOM from "react-dom/client";
import { ShoppingCartContext } from "./context/ShoppingCartContext";
import { store } from "./store/Store";
import { Provider } from "react-redux";
import { AuthProvider } from './context/AuthContext';

// Import CSS file
import "./index.css";

// Import i18n
import "./i18n";
// Import component App
import App from "./App";

// Import Bootstrap 5
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/js/bootstrap.min.js";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <AuthProvider>
        <ShoppingCartContext>
          <App />
        </ShoppingCartContext>
      </AuthProvider>
    </Provider>
  </React.StrictMode>
);
