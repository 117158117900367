import React, { useEffect } from 'react'
import Carousel from 'react-bootstrap/Carousel'
import { useTranslation } from 'react-i18next';
import { useDispatch , useSelector } from 'react-redux';
import PropagateLoader from 'react-spinners/PropagateLoader';
import { fetchCover } from '../../../features/slidercover/SliderCoverSlice';
import { specialOffersImgUrl} from './../../../url/UrlHelpes'
// import css files
import '../../../assets/css/Home/Sliders/CarouselPage.css'



const CarouselPage = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { status , cover , error } = useSelector((state) => state.cover);

  useEffect(() => {
    dispatch(fetchCover())

  } , [dispatch]);
 
  if (error) {
    return <div className="text-center">Error: {error}</div>;
  }

  if (!cover) {
    return <div className='text-center'>No cover found</div>;
  }

  if (status === "loading") {
    return  <div className="text-center">
          <p className='pt-5 pb-5'>
            <PropagateLoader className='mt-2 mb-2'  size={17} color={"#cf9a52"}  />
          </p>
        </div>
  }else if (status === "succeeded") {

    return <>
    <Carousel>
      {cover.map((cover) =>(
        <Carousel.Item key={cover.id}>
        <img 
          className="d-block w-100 ima-box-slider"
          src={`${specialOffersImgUrl}/${cover.image}`}
          alt="First slide"
        />
        <Carousel.Caption className="carousel-caption-1">
          <h3>
            {cover.title}
          </h3> 
        </Carousel.Caption>
        <Carousel.Caption>
          <button type="button" className="btn btn-secondary">{t('CarouselPage.button')}</button>
        </Carousel.Caption> 
      </Carousel.Item>
      ))}
    </Carousel>
  </>
   
  }else if (status === "failed") {
    return <div>Error: {error}</div>;
  }
}

export default CarouselPage;