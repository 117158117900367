import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {apiUrl } from './../../url/UrlHelpes'

export const fetchProductCompositionOneById = createAsyncThunk(
  "ProductCompositionOne/fetchProductCompositionOneById",
  async (productId) => {
    const response = await fetch(
      `${apiUrl}/products/${productId}`
    );
    const data = await response.json();
    return data;
  }
);


const initialState = {
    ProductCompositionOne: {},
    status: null,
  };
  
  const ProductCompositionOneByIdSlice = createSlice({
    name: "ProductCompositionOne",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchProductCompositionOneById.pending, (state) => {
          state.status = "loading";
        })
        .addCase(fetchProductCompositionOneById.fulfilled, (state, { payload: ProductCompositionOne }) => {
          state.status = "succeeded";
          state.ProductCompositionOne = ProductCompositionOne;
        })
        .addCase(fetchProductCompositionOneById.rejected, (state, action) => {
          state.status = "failed";
          state.error = action.error.message;
        });
    },
  });
  
  export default ProductCompositionOneByIdSlice.reducer;