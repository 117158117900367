import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { apiUrl } from "./../../url/UrlHelpes";

// Fetch all blogs
export const fetchBlogs = createAsyncThunk("blogs/fetchBlogs", async () => {
  const response = await axios.get(`${apiUrl}/blogs`);
  return response.data;
});

// Fetch a single blog by ID
export const fetchBlogById = createAsyncThunk("blogs/fetchBlogById", async (id) => {
  const response = await axios.get(`${apiUrl}/blogs/${id}`);
  return response.data;
});

export const blogsSlice = createSlice({
  name: "blogs",
  initialState: {
    blogs: [],
    blog: null,
    status: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch all blogs
      .addCase(fetchBlogs.fulfilled, (state, { payload }) => {
        state.blogs = payload;
        state.status = "success";
      })
      .addCase(fetchBlogs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchBlogs.rejected, (state, { error }) => {
        state.status = "failed";
        state.error = error.message;
      })
      // Fetch a single blog by ID
      .addCase(fetchBlogById.fulfilled, (state, { payload }) => {
        state.blog = payload;
        state.status = "success";
      })
      .addCase(fetchBlogById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchBlogById.rejected, (state, { error }) => {
        state.status = "failed";
        state.error = error.message;
      });
  },
});

export default blogsSlice.reducer;
