import React, { useEffect } from "react";
import { Stack, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductMielByIdCart } from "./../../features/product/ProductMielByIdCartSlice";
import { productImgUrl } from "./../../url/UrlHelpes";
import PropagateLoader from 'react-spinners/PropagateLoader';
// import css files
import "./../../assets/css/shopping/CartItems.css";
// import useContext
import { useCart } from "../../context/ShoppingCartContext";

import FormatCurrency from "./../../function/FormatCurrency";

const CartItem = ({
  productId,
  quantityPoduct,
  categoryId,
  categoryName,
  categoryPricePoroduct,
}) => {
  const { increaseCartQuantity, decreaseCartQuantity, removeProductFromCart } = useCart();
  const dispatch = useDispatch();
  const productMiel = useSelector((state) => state.productmiel.products[productId]);
  const status = useSelector((state) => state.productmiel.status);
  const error = useSelector((state) => state.productmiel.error);

  useEffect(() => {
    if (!productMiel) {
      dispatch(fetchProductMielByIdCart(productId));
    }
  }, [dispatch, productId, productMiel]);

  if (!productMiel) return null;
  if (status === "loading") {
    return (
      <div className="text-center">
        <p className='pt-5 pb-5'>
          <PropagateLoader size={10} color={"#cf9a52"} />
        </p>
      </div>
    );
  
  } else if (
    status === "succeeded" &&
    productMiel.categorie[0].title === "Miel"
  ) {
    return (
      <Stack
      direction="horizontal"
      gap={2}
      className="d-flex align-items-center"
    >
      <img
        src={`${productImgUrl}/${productMiel.image}`}
        alt="cart-img"
        style={{ width: "70px", height: "75px", objectFit: "cover" }}
      />
      <div className="me-auto">
        <div>
          {productMiel.title}
          {quantityPoduct > 1 && (
            <span className="text-muted" style={{ fontSize: "0.65rem" }}>
              x{quantityPoduct}
            </span>
          )}
        </div>
        <div className="text-muted" style={{ fontSize: "0.75rem"}}>
          {FormatCurrency(categoryPricePoroduct)}&emsp;&emsp; {categoryName}g
        </div>
        <div className="col-md-12 d-flex align-items-center pt-2 quantity-panier-container">
          <button
            disabled={quantityPoduct < 2}
            type="button"
            className="btn btn-minus button-panier-quantity"
            onClick={() => decreaseCartQuantity(productId, categoryId)}
          >
            -
          </button>
          <input
            type="text"
            className="text-center m-1 input-panier-quantity"
            min="1"
            value={quantityPoduct}
            readOnly
          />
          <button
            type="button"
            className="btn btn-plus button-panier-quantity"
            onClick={() => increaseCartQuantity(productId, categoryId)}
          >
            +
          </button>
        </div>
      </div>
      <div>
        {FormatCurrency(categoryPricePoroduct * quantityPoduct)}
      </div>
      <Button
        variant="outline-danger"
        size="sm"
        onClick={() => removeProductFromCart(productId, categoryId)}
      >
        &times;
      </Button>
    </Stack>
    );
  } else {
    return <p>{error}</p>;
  }
};

export default CartItem;
