import React, { useEffect } from "react";

import PropagateLoader from 'react-spinners/PropagateLoader';
import { useCart } from "../../context/ShoppingCartContext";
import {productImgUrl } from './../../url/UrlHelpes'
import FormatCurrency from "../../function/FormatCurrency";

import { useDispatch, useSelector } from "react-redux";
import { fetchProductCoffretByIdCart } from "./../../features/product/ProductCoffretByIdCartSlice";

const CheckoutCartitmCoffret = ({
  productId,
  quantityCoffret,
  priceCoffret,
}) => {
  const {
    increaseCartQuantityCoffret,
    decreaseCartQuantityCoffret,
    removeCoffretFromCart,
  } = useCart();
  const dispatch = useDispatch();
  const { productCoffret, status, error } = useSelector((state) => state.productcoffret);

  useEffect(() => {
    if (productId) {
      dispatch(fetchProductCoffretByIdCart(productId));
    }
  }, [dispatch, productId]);
  

  if (productCoffret == null) return null;

  if(status === 'loading') return (
    <div className="text-center">
      <p className='pt-5 pb-5'>
        <PropagateLoader className=''  size={5} color={"#cf9a52"}  />
      </p>
    </div>
  )
  return (
    <>
      {quantityCoffret > 0 && (
        <div key={productCoffret.id} className="row border-top border-bottom ">
          <div className="row main align-items-center">
            <div className="col-2 box-img">
              <img
                className="img-fluid"
                src={`${productImgUrl}/${productCoffret?.image}`}
              />
            </div>
            <div className="col">
              <div className="row text-muted" style={{ color: "#00000080" }}>
                <div className="col-md-10">{productCoffret.title}</div>
                {quantityCoffret > 1 && (
                  <div className="col-md-2" style={{ fontSize: "0.65rem" }}>
                    x{quantityCoffret}
                  </div>
                )}
              </div>
              <div className="row" style={{ color: "#00000080" }}>
                {}
              </div>
            </div>
            <div className="col">
              <div className="row d-flex justify-content-center">
                <div className="col-3 d-flex justify-content-end pe-0 ">
                  {quantityCoffret > 1 && (
                    <div className="col-3 d-flex justify-content-end pe-0 ">
                      <button
                        type="button"
                        className="btn btn-minus"
                        onClick={() => decreaseCartQuantityCoffret(productId)}
                      >
                        -
                      </button>
                    </div>
                  )}
                </div>
                <div className="col-6 d-flex justify-content-center ps-0 pe-0 ">
                  <input
                    type="text"
                    className="text-center input-panier-quantity"
                    min="1"
                    value={quantityCoffret}
                    readOnly
                  />
                </div>
                <div className="col-3 d-flex justify-content-start ps-0 ">
                  <button
                    type="button"
                    className="btn btn-plus "
                    onClick={() => increaseCartQuantityCoffret(productId)}
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
            <div className="col">
              <span className="price-panier">
                {FormatCurrency(priceCoffret * quantityCoffret)}
              </span>
              <span className="close">
                <a
                  href="#"
                  className="text-decoration-none"
                  onClick={() => removeCoffretFromCart(productId)}
                >
                  &#10005;
                </a>
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CheckoutCartitmCoffret;
