import React, { useState } from "react";
import HeaderUpdate from "../../../layouts/Header/HeaderUpdate";
import Footer from "../../../layouts/footer/footer";
import { apiUrl } from "../../../url/UrlHelpes";
import "./../login/LoginForm.css";
import { Link } from "react-router-dom";
import ProgressBar from "../../../views/ProgressBar ";
import { useTranslation } from "react-i18next";

const Forgotpassword = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    const formData = new FormData();
    formData.append("email", email);
    event.preventDefault();

    try {
      const response = await fetch(`${apiUrl}/forgot-password`, {
        method: "POST",
        body: formData,
      });

      const data = await response.json();

      if (response.ok) {
        setSuccessMessage(data.message);
        setError("");
      } else {
        setError(data.message);
        setSuccessMessage("");
      }
    } catch (error) {
      setError("Une erreur s'est produite. Veuillez réessayer.");
      setSuccessMessage("");
    }
  };

  return (
    <>
      <HeaderUpdate />
      <ProgressBar />
      <div className="container pt-5" id="login-frm">
        <div className="row align-items-center">
          <div className="col-md-12 col-lg-12 mb-3 text-center d-inline">
            <h2
              className="fw-bold fs-1 mt-5 fr-mobile-forget"
              style={{ color: "#d59a48", fontFamily: "Montserrat-Regular" }}
            >
              Mot de passe oublié
            </h2>
          </div>
        </div>
        <div className="row justify-content-center">
          <form className="login-form logi-frm" onSubmit={handleSubmit}>
            <div className="col-12 mb-2">
              <div className="input-box">
                <label htmlFor="email" className="form-label">
                  Email<span className="clr-red">*</span>
                </label>
                <input
                  type="email"
                  name="email"
                  value={email}
                  onChange={handleEmailChange}
                />
              </div>
            </div>
            {error && <div className="error-message">{error}</div>}
            {successMessage && (
              <div className="success-message">{successMessage}</div>
            )}
            <div className="col-12 mb-2">
              <Link
                style={{ fontFamily: "Montserrat-Regular" }}
                className="nav-link clr-dark ff-varela-round fw-600 hover-color fr-forget"
                to="/register"
              >
                {t("Auth.Vousnavezpasdecompte?Signup")}
              </Link>
            </div>
            <div className="col-12 mt-4">
              <button type="submit" className="btn submit-btn">
                {t("Auth.Envoyé")}
              </button>
            </div>
            {/* <hr className="hr-tag" /> */}
          </form>
        </div>
      </div>
      <br />
      <br />
      <Footer />
    </>
  );
};

export default Forgotpassword;
