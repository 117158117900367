import React, { useEffect } from "react";
import { Stack, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductCoffretByIdCart } from "./../../features/product/ProductCoffretByIdCartSlice";
import {productImgUrl } from './../../url/UrlHelpes'
import PropagateLoader from 'react-spinners/PropagateLoader';

// import css files
import "./../../assets/css/shopping/CartItems.css";
// import useContext
import { useCart } from "../../context/ShoppingCartContext";

import FormatCurrency from "./../../function/FormatCurrency";
const ItemsCoffert = ({ productId, quantityCoffret, priceCoffret }) => {
  const {
    increaseCartQuantityCoffret,
    decreaseCartQuantityCoffret,
    removeCoffretFromCart,
  } = useCart();

  const dispatch = useDispatch();
  const { productCoffret, status, error } = useSelector(
    (state) => state.productcoffret
  );

  useEffect(() => {
    dispatch(fetchProductCoffretByIdCart(productId));
  }, [dispatch]);

  if (productCoffret == null) return null;

  if (status === "loading") {
    return(
      <div className="text-center">
            <p className='pt-5 pb-5'>
              <PropagateLoader className=''  size={10} color={"#cf9a52"}  />
            </p>
      </div>
    )  
  } else if (
    status === "succeeded" &&
    productCoffret.categorie[0].title === "Coffrets"
  ) {
    return (
      <Stack
        direction="horizontal"
        gap={2}
        className="d-flex align-items-center"
      >
        <img
          src={`${productImgUrl}/${productCoffret?.image}`}
          alt="cart-img"
          style={{ width: "70px", height: "75px", objectFit: "cover" }}
        />
        <div className="me-auto">
          <div>
            {productCoffret.title}{" "}
            {quantityCoffret > 1 && (
              <span className="text-muted" style={{ fontSize: "0.65rem" }}>
                x{quantityCoffret}
              </span>
            )}
          </div>
          <div className="text-muted" style={{ fontSize: "0.75rem" }}>
            {FormatCurrency(priceCoffret)}&emsp;&emsp; {}
          </div>

          <div className="col-md-12 d-flex align-items-center pt-2 quantity-panier-container">
            <button
              disabled={quantityCoffret < 2}
              type="button"
              className="btn btn-minus button-panier-quantity"
              onClick={() => decreaseCartQuantityCoffret(productId)}
            >
              -
            </button>

            <input
              type="text"
              className="text-center m-1 input-panier-quantity"
              min="1"
              value={quantityCoffret}
              readOnly
            />
            <button
              style={{}}
              type="button"
              className="btn btn-plus button-panier-quantity"
              onClick={() => increaseCartQuantityCoffret(productId)}
            >
              +
            </button>
          </div>
        </div>

        <div>{FormatCurrency(priceCoffret * quantityCoffret)}</div>
        <Button
          variant="outline-danger btn-remove-item"
          size="sm"
          onClick={() => removeCoffretFromCart(productId)}
        >
          &times;
        </Button>
      </Stack>
    );
  } else {
    return <p>{error}</p>;
  }
};

export default ItemsCoffert;
