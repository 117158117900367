import React from "react";
import { useTranslation } from "react-i18next";
import "./../assets/css/Notreboutique.css";
import Footer from "../layouts/footer/footer";
import logo from "./../assets/images/logo-page-detail.png";
import HeaderUpdate from "../layouts/Header/HeaderUpdate";
import bo1 from "./../assets/images/BOUT-MIEL.jpg";
import bo2 from "./../assets/images/POT-MIEL.jpg";
import bo3 from "./../assets/images/INTERIEUR.jpg";
import ProgressBar from "./ProgressBar ";

const Notreboutique = () => {
  const { t } = useTranslation();
  return (
    <>
      <HeaderUpdate />
      <ProgressBar />
      <section className="container-fluid container-fluid-boutique p-0 m-0">
        <div className="container container-boutique">
          <div className="row w-100">
            <div className="col-md-12 w-100">
              <div className="col-md-12 ms-2">
                <h1 className="text-center">{t("boutique.title")}</h1>
              </div>
              <div className="col-md-12 ms-2">
                <img
                  src={logo}
                  className="img-fluid  m-auto d-block boutique-img-logo "
                  alt=""
                />
              </div>
              <div className="row ">
                <div className="col-md-12 d-flex justify-content-center align-content-center">
                  <h3 className="text-center">{t("boutique.sotitle")}</h3>
                </div>
              </div>
              <div className="col-md-12">
                <p className="m-auto">
                  {t("boutique.paragraph1.translated-text1")}
                  <br />
                  <br />
                  {t("boutique.paragraph1.translated-text2")} <br />
                  <br />
                  {t("boutique.paragraph1.translated-text3")} <br />
                  <br />
                  {t("boutique.paragraph1.translated-text4")}{" "}
                </p>
              </div>
              <div className="row">
                <div
                  className="d-flex m-auto justify-content-between img-zome"
                  style={{ width: "92%" }}
                >
                  <div className="col-md-4">
                    <img src={bo1} className="img-fluid image-item" alt="" />
                  </div>
                  <div className="col-md-4">
                    <img src={bo3} className="img-fluid image-item" alt="" />
                  </div>
                  <div className="col-md-4">
                    <img src={bo2} className="img-fluid image-item" alt="" />
                  </div>
                </div>
              </div>

              <div className="col-md-12 mt-5  text-center">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2623.666808390416!2d2.337533675567839!3d48.88362799893134!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66f2521d61dbd%3A0xe01b2b3d4be2527c!2sMiel%20Montet!5e0!3m2!1sfr!2sma!4v1709227608162!5m2!1sfr!2sma"
                  width="92%"
                  height="400"
                  style={{ border: "0", marginBottom: "100px" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
              <br />
              <br />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Notreboutique;
