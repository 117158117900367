import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { productImgUrl } from "./../../../url/UrlHelpes";
import PropagateLoader from "react-spinners/PropagateLoader";
import { useSelector, useDispatch } from "react-redux";
import { fetchProducts } from "../../../features/product/ProductsSlice";
import { useTranslation } from "react-i18next";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./../../../assets/css/Home/Sliders/CardsSlider.css";

// Import the components
import Slider from "react-slick";

const CardsSlider = ({ title, category }) => {
  const { t, i18n } = useTranslation();
  const data = useSelector((state) => state.products.products); // Select the products array directly
  const { status, error } = useSelector((state) => state.products); // No need to destructure `data` again
  const dispatch = useDispatch();
  const categorieName = category ? category : "Miel";

  // Determine the language ID based on the current language
  const languageId = i18n.language === 'fr' ? 1 : 2;

  useEffect(() => {
    dispatch(fetchProducts(languageId));
  }, [dispatch, languageId]);

  // Filter products based on the category, ensuring data.products is defined
  const ProductData = data?.filter(
    (product) => product.categorie[0].title === categorieName
  ) || [];


  // Slider settings
  const settings = {
    dots: true,
    infinite: ProductData.length > 1, // Enable infinite loop only if there are more than 1 product
    speed: 500,
    slidesToShow: ProductData.length === 1 ? 1 : 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: ProductData.length === 1 ? 1 : 2,
          slidesToScroll: 2,
          infinite: ProductData.length > 2, // Enable infinite loop only if there are more than 2 products
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div
      id="slider-container-cards"
      className="slider-container mt-4 mb-5 mb-lg-5"
      style={{ width: "90%", margin: "auto" }}
    >
      <h3 className="text-center">{title || t("header.Honeys")}</h3>
      <Slider {...settings}>
        {status === "loading" ? (
          <div className="text-center">
            <p className="pt-5 pb-5">
              <PropagateLoader
                className="mt-2 mb-2"
                size={17}
                color={"#cf9a52"}
              />
            </p>
          </div>
        ) : ProductData.length > 0 ? (
          ProductData.map((product) => {
            const translation = product.translates.find(t => t.language.id === languageId) || {};
            return (
              <div
                key={product.id}
                className="col-sm-12 mb-3 mt-3 d-flex box-cards"
              >
                <Link
                  className="text-decoration-none m-auto"
                  to={`/detail-miel/${product.id}`}
                >
                  <div className="card card-image m-auto">
                    <img
                      src={`${productImgUrl}/${product.image}`}
                      className="card-img-top background-image"
                      alt={translation.title || product.title}
                    />
                    <div className="card-body d-flex flex-column">
                      <h5 className="card-title">
                        {i18n.language === 'fr' ? product.title : (translation.title || product.title)}
                      </h5>
                      <p className="card-text card-text-slider mb-4">
                        {product.categorie
                          .filter((cat) => cat.title !== "Miel" && cat.title !== "Coffrets")
                          .map((cat) => cat.title)
                          .join(", ")}
                      </p>
                      <Link
                        to={`/detail-miel/${product.id}`}
                        className="text-black mt-auto align-self-left"
                      >
                        {t("listemiels.Lireplus")}
                      </Link>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })
        ) : !error ? (
          <div className="text-center">No products found</div>
        ) : (
          <div className="text-center">{error}</div>
        )}
      </Slider>
    </div>
  );
};

export default CardsSlider;
