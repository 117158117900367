import React , { useContext, useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { FaUserGear } from "react-icons/fa6";
import { apiUrl } from '../../../url/UrlHelpes';
import useApiRequest from '@mbs-dev/api-request'
import PropagateLoader from 'react-spinners/PropagateLoader';
import { LuShoppingBag } from "react-icons/lu";
import { MdOutlineLogout } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/images/logo-page-detail.png";
import './../../../assets/css/account/profile/UserProfile.css';
import HeaderUpdate from '../../../layouts/Header/HeaderUpdate';
import Footer from '../../../layouts/footer/footer';
import MessageSuccess from '../../../function/addMessageSuccess';
import { AuthContext } from '../../../context/AuthContext';

const UserProfile = () => {
    const navigate = useNavigate();
    const { apiRequest } = useApiRequest()
    const [loading, setLoading] = useState(false);
    const [orderLoading, setOrderLoading] = useState(false);
    const [userId, setUserId] = useState()
    const [userEmail, setUserEmail] = useState('')
    const [smsPasswordCrt, setSmsPasswordCrt] = useState('')
    const [smsPasswordConfi, setSmsPasswordConfi] = useState('')
    const [passIsloading, setpassIsloading] = useState(false);
    const { register: registerPersonalInfo, handleSubmit: handleSubmitPersonalInfo, formState: { errors: errorsPersonalInfo } } = useForm();
    const { register: registerChangePassword, handleSubmit: handleSubmitChangePassword, reset: resetChangePassword, formState: { errors: errorsChangePassword } } = useForm();
    const token =  localStorage.getItem('mbs_user_token')
    const [ userProfile , setUserPrpfile ] = useState({})
    const [userOrders, setUserOrders] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [ordersPerPage] = useState(10);
    const { setIsAuthenticated } = useContext(AuthContext);

    const fetchData = async () => { 

        try{
            const userData = await apiRequest({
                route: `${apiUrl}/me`,
                method: 'GET',
                requiresAuth: true,
                token: token
            })
            if (userData.status === 200) {
                setUserId(userData.data.id)
                setUserEmail(userData.data.email)
                setUserPrpfile(userData.data)
            }

        }catch(error){
            // console.error('Error:', error);
        }
    }
    useEffect(() => {
        if(token){
            fetchData();
        }
    }, [token])


    const fetchOrders = async () => {
        try {
            setOrderLoading(true);
            const response = await apiRequest({
                route: `${apiUrl}/orders.json?user.id=${userId}`,
                method: 'GET',
                requiresAuth: true,
                token: token,
            });
            if (response.status === 200) {
                setUserOrders(response.data);
            } else {
                // console.error('Error fetching orders:', response.status);
            }
        } catch (error) {
            // console.error('Error:', error);
        } finally {
            setOrderLoading(false);
        }
    };

    useEffect(() => {
        if (token) {
            fetchOrders();
        }
    }, [token]);

    const handleClick = (event, pageNumber) => {
        event.preventDefault();
        setCurrentPage(pageNumber);
    };

    const indexOfLastOrder = currentPage * ordersPerPage;
    const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
    const currentOrders = userOrders ? userOrders.slice(indexOfFirstOrder, indexOfLastOrder) : [];

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil((userOrders ? userOrders.length : 0) / ordersPerPage); i++) {
        pageNumbers.push(i);
    }
    
    
    const onSubmitPersonalInfo = async (data) => {
        
        try {
            setLoading(true);
            const requestBody = {
            
                email: data['your-email'],
                lastName: data['last-name'],
                firstName: data['first-name'],
                telephone: data['telephone'],
                status: 0
            };
    
            const response = await fetch(`${apiUrl}/users/${userId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(requestBody)
            });
    
            if (response.ok) {
                MessageSuccess('Votre profile bien été bien modifie');
            }
        } catch (error) {
            // console.error('Error:', error);
        } finally {
            setLoading(false);
        }
        
    };

    const onSubmitChangePassword = async (data) => {
        try {
            setpassIsloading(true);
    
            if (data['confirm-pass'] !== data['new-pass']) {
                setSmsPasswordConfi('Les mots de passe ne correspondent pas.')

                setpassIsloading(false);
                return;
            }
    
            const formData = new FormData();
            formData.append('password', data['your-pass-act']);
            formData.append('newpassword', data['confirm-pass']);
            formData.append('email', String(userEmail));
    
            const response = await apiRequest({
                route: `${apiUrl}/edit-password`,
                method: 'POST',
                data: formData,
                requiresAuth: true,
                token: token
            });
    
            if (response.status !== 200) {
                alert('Le mot de passe n\'a pas été modifié');
                setpassIsloading(false);
            }
    
            if (response.status === 200) {
                if (response.data.status === true) {
                    MessageSuccess('Le mot de passe a été modifié avec succès');
                    setpassIsloading(false);
                    setSmsPasswordCrt('');
                    setSmsPasswordConfi('');
                    resetChangePassword();
                } else {
                    setSmsPasswordCrt(response.data.message);
                    setpassIsloading(false);
                }
            }
        } catch (error) {
            // console.error('Error:', error);
        }
    }
        
    const handlelogout = () => {
        localStorage.removeItem('mbs_user_token')
        localStorage.setItem('isAuthenticated',false)
        navigate('/login')
    }
    
    return (
        <>
            <HeaderUpdate />
            <div className="container-fluid user-profile">
                <h1 className="text-center">Mon compte</h1>
                <img src={logo} className="img-fluid p-1 m-auto d-block composition-img-logo" alt="" />
                <nav className='mt-5 d-flex justify-content-center'>
                    <div className="row m-auto w-100 g-4 justify-content-around mb-5">
                        <div className="col-lg-3 col-xl-3 col-md-12 col-sm-12 vartical-nav">
                            <div className="nav nav-tabs d-grid border-0" id="nav-tab" role="tablist">
                                <a className="nav-link w-100" id="nav-Commandes-tab" data-bs-toggle="tab" href="#nav-Commandes" role="tab" aria-controls="nav-Commandes" aria-selected="false">
                                    <LuShoppingBag className='me-2 fs-6' />
                                    COMMANDES
                                </a>
                                <a className="nav-link w-100" id="nav-details-tab" data-bs-toggle="tab" href="#nav-details" role="tab" aria-controls="nav-details" aria-selected="false">
                                    <FaUserGear className='me-2 fs-6' />DÉTAILS DU COMPTE
                                </a>
                                <a className="nav-link w-100" onClick={handlelogout} style={{ cursor: 'pointer' }} >
                                    <MdOutlineLogout className='me-2 fs-6' />
                                    DÉCONNEXION
                                </a>
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 nav-content">
                            <div className="tab-content" id="nav-tabContent">
                                <div className="tab-pane fade show active" id="nav-Commandes" role="tabpanel" aria-labelledby="nav-Commandes-tab">
                                    <div className="container container-command">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h4 className="text-left mb-4">Liste Commande</h4>
                                                <div className="table-wrap table-responsive">
                                                    <table className="table custom-table">
                                                        <thead className="thead-primary">
                                                            <tr>
                                                                <th className='text-center'>Nom et Prénom</th>
                                                                <th className='text-center'>Passé le</th>
                                                                <th className='text-center'>Quantity</th>
                                                                <th className='text-center'>Total</th>
                                                                <th className='text-center'>M.D.Payment</th>
                                                                <th className='text-center'>Payment Status</th>
                                                                <th className='text-center'>Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {orderLoading ? (
                                                                <tr >
                                                                <td className='text-center' colSpan='7' rowSpan={currentOrders && currentOrders.length}>
                                                                    <p className='pt-5 pb-5'>
                                                                        <PropagateLoader className='mt-2 mb-2'  size={17} color={"#cf9a52"}  />
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                                 
                                                            ) : (
                                                                currentOrders && currentOrders.length > 0 ? (
                                                                    currentOrders.map((order, index) => (
                                                                        <tr key={index}>
                                                                            <td className="text-center">{order?.user?.fullName}</td>                                                                            <td className='text-center'>{new Date(order.updatedAt).toLocaleDateString('en-GB')}</td>
                                                                            <td className='text-center'>{order?.itemsQuantity}</td>
                                                                            <td className='text-center'>{order?.totalPrice}€</td>
                                                                            <td className='text-center'>{order?.paymentMethod}</td>
                                                                            <td 
                                                                                className={`
                                                                                    text-center fw-bold 
                                                                                    ${order.paymentStatus === 'Payé' ? 'text-success' : 'text-primary'}
                                                                                `}>
                                                                                {order.paymentStatus}
                                                                            </td>
                                                                            <td 
                                                                                className={`
                                                                                    text-center fw-bold
                                                                                    ${order?.orderStatus === 'Validé' ? 'text-success' : 
                                                                                    order?.orderStatus === 'Annuler' ? 'text-warning' : 
                                                                                    order?.orderStatus === 'En cours' ? 'text-primary' : ''}
                                                                                `}>
                                                                                {order?.orderStatus}
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                ) : null
                                                            )}
                                                        </tbody>
                                                    </table>
                                                    <nav>
                                                        <ul className="pagination justify-content-center">
                                                            {pageNumbers.map(number => (
                                                                <li key={number} className="page-item">
                                                                    <a onClick={(e) => handleClick(e, number)} href="!#" className="page-link shadow-none">
                                                                        {number}
                                                                    </a>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </nav>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="nav-details" role="tabpanel" aria-labelledby="nav-details-tab">
                                    <div className="container container-details-compte">
                                    <h4 className="text-left mt-3" >Détails du compte</h4>
                                        <form className='form-content' onSubmit={handleSubmitPersonalInfo(onSubmitPersonalInfo)}>
                                            <div className="row g-3 mt-2 mb-5">
                                                <div className="col-md-12">
                                                    <label htmlFor="last-name" className="form-label label-form-contact">Nom</label>
                                                    <input 
                                                        defaultValue = {userProfile.lastName}
                                                        type="text" 
                                                        className="form-control input-form-contact" 
                                                                    id="last-name" 
                                                        name="last-name"
                                                        {...registerPersonalInfo("last-name", { required: true })} 
                                                    />
                                                    {errorsPersonalInfo['last-name'] && !userProfile.lastName &&  <span className="text-danger">Nom is required</span>}
                                                </div>
                                                <div className="col-md-12">
                                                    <label htmlFor="first-name" className="form-label label-form-contact">Prenom</label>
                                                    <input 
                                                        type="text" 
                                                        defaultValue = {userProfile.firstName} 
                                                        className="form-control input-form-contact" 
                                                        id="first-name" 
                                                        name="first-name" 
                                                        {...registerPersonalInfo("first-name", { required: true })} 
                                                    />
                                                    {errorsPersonalInfo['first-name'] && !userProfile.firstName && <span className="text-danger">Prenom is required</span>}
                                                </div>
                                                <div className="col-md-12">
                                                    <label htmlFor="your-email" className="form-label label-form-contact">Adresse email</label>
                                                    <input 
                                                        type="email" 
                                                        defaultValue = {userProfile.email} 
                                                        className="form-control input-form-contact" 
                                                        id="your-email" 
                                                        name="your-email" {...registerPersonalInfo("your-email", { required: true })} 
                                                    />
                                                    {errorsPersonalInfo['your-email'] && !userProfile.email && <span className="text-danger">Adresse email is required</span>}
                                                </div>
                                                <div className="col-md-12">
                                                    <label htmlFor="telephone" className="form-label label-form-contact">Telephone</label>
                                                    <input 
                                                        type="text" 
                                                        defaultValue = {userProfile.telephone} 
                                                        className="form-control input-form-contact" 
                                                        id="telephone" 
                                                        name="telephone" {...registerPersonalInfo("telephone", { required: true })} 
                                                    />
                                                    {errorsPersonalInfo['telephone'] && !userProfile.telephone && <span className="text-danger">Telephone is required</span>}
                                                </div>
                                                <div className="col-md-12" >
                                                    <div className="row w-100">
                                                        <div className="col-md-12">
                                                            <button type="submit" className="btn  text-left  fw-bold" >
                                                                {loading? 'Enregistrer les modifications...' : 'Enregistrer les modifications'}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        <form className='form-content' onSubmit={handleSubmitChangePassword(onSubmitChangePassword)}>
                                            <div className="row g-3 mt-5 mb-5">
                                                <div className="col-md-12">
                                                    <label htmlFor="your-pass-act" className="form-label label-form-contact">Mot de passe actuel</label>
                                                    <input 
                                                        type="password" 
                                                        className="form-control input-form-contact input-form-contact" 
                                                        id="your-pass-act" 
                                                        name="your-pass-act" 
                                                        {...registerChangePassword("your-pass-act", { required: true })} 
                                                    />
                                                    {errorsChangePassword['your-pass-act'] && <span className="text-danger">Mot de passe actuel is required</span>}<br/>
                                                    {smsPasswordCrt && <span className="text-danger">{smsPasswordCrt}</span>}
                                                </div>
                                                <div className="col-md-12">
                                                    <label htmlFor="new-pass" className="form-label label-form-contact">Nouveau mot de passe</label>
                                                    <input 
                                                        type="password" 
                                                        className="form-control input-form-contact" 
                                                        id="new-pass" 
                                                        name="new-pass" 
                                                        {...registerChangePassword("new-pass", { required: true })} 
                                                    />
                                                    {errorsChangePassword['new-pass'] && <span className="text-danger">Nouveau mot de passe is required</span>}  
                                                </div>
                                                <div className="col-md-12">
                                                    <label htmlFor="confirm-pass" className="form-label label-form-contact">Confirmer votre mot de passe</label>
                                                    <input 
                                                        type="password" 
                                                        className="form-control input-form-contact" 
                                                        id="confirm-pass" 
                                                        name="confirm-pass" 
                                                        {...registerChangePassword("confirm-pass", { required: true })} 
                                                    />
                                                    {errorsChangePassword['confirm-pass'] && <span className="text-danger">Confirmer votre mot de passe is required</span>}<br/>
                                                    {smsPasswordConfi && <span className="text-danger">{smsPasswordConfi}</span>}
                                                    
                                                </div>                    
                                                <div className="col-md-12" >
                                                    <div className="row w-100">
                                                        <div className="col-md-12">
                                                            <button type="submit" className="btn text-left  fw-bold" >
                                                                {passIsloading ? "Modifier votre mot de passe ..." : "Modifier votre mot de passe"}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
            <Footer />
        </>
    )
}

export default UserProfile;
