import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {apiUrl } from './../../url/UrlHelpes'

export const fetchProductById = createAsyncThunk(
  "switchproductById/fetchProductById",
  async (productId) => {
    const response = await fetch(`${apiUrl}/products.json/${productId}`);
    const data = await response.json();
    return data;
  }
);

const initialState = {
  switchproductById: {},
  status: null,
  error: null,
};

const productByIdSlice = createSlice({
  name: "switchproductById",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProductById.fulfilled, (state, { payload }) => {
        state.status = "succeeded";
        state.switchproductById[payload.id] = payload; // Assuming product ID is unique
      })
      .addCase(fetchProductById.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default productByIdSlice.reducer;
