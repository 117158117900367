import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategories } from "../../features/categories/CategorieSlice";
import { filterProductsByCategory } from "../../features/product/ProductsSlice";
import "./../../assets/css/Nos-Miels/filtermiels.css";
import icon from "../../assets/images/WhatsApp Image 2024-06-13 at 11.04.31.jpeg";

const Filtermiels = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { categories, status } = useSelector((state) => state.categorie);

  const [selectedCategory, setSelectedCategory] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchCategories());
    }

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [status, dispatch]);

  const handleCategoryChange = (categoryTitle) => {
    setSelectedCategory(categoryTitle);
    dispatch(filterProductsByCategory(categoryTitle));
    setIsDropdownOpen(false);
  };

  const handleCategoryChangee = (event) => {
    const selectedValue = event.target.value;
    setSelectedCategory(selectedValue);
    dispatch(filterProductsByCategory(selectedValue));
    setIsDropdownOpen(false);
  };
  
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const renderDropdownOptions = () => {
    return categories
      .filter((category) => category.title === "Miel")
      .flatMap((category) =>
        category.subCategory.map((subCategory) => (
          <div
            key={subCategory.title}
            className={`dropdown-item ${
              selectedCategory === subCategory.title ? "active" : ""
            }`}
            onClick={() => handleCategoryChange(subCategory.title)}
          >
            {subCategory.title}
          </div>
        ))
      );
  };

  return (
    <div className="card cardbody mt-0">
      <div className="card-body miels-body mt-0 pt-0">
        <nav className="navbar navbar-expand-lg pt-0 nav-miels" style={{ borderBottom: 'none' }}>
          {isMobile ? (
            <div className="d-flex justify-content-between w-100 align-items-center">
              <Link to="/" className="navbar-brand accueil-menu">
                {t("footer.MielMontet")}
              </Link>
              <button
                style={{
                  border: "#cf9a52 1px solid",
                  boxShadow: isDropdownOpen ? "0 0 5px #cf9a52" : "none",
                }}
                onClick={toggleDropdown}
                className="btn dropdown-toggle d-flex align-items-center"
              >
                <img
                  src={icon}
                  alt="Toggle categories"
                  style={{ width: "25px", height: "25px" }}
                />
              </button>
            </div>
          ) : (
            <>
              <Link to="/" className="navbar-brand accueil-menu">
                {t("footer.MielMontet")} {">"}
              </Link>
              <a className="navbar-brand name-nav" style={{ marginLeft: "-17px" }}>
                {t("listemiels.title")}
              </a>
            </>
          )}
        </nav>
        <div className="col-auto-12 mb-2 car-title">
          <div className="form-check mb-1 radio-item-miel">
            <input
              className="form-check-input"
              type="radio"
              value="Tous Produits"
              id="radioAllCategories"
              checked={!selectedCategory}
              onChange={handleCategoryChangee}
            />
            <label
              className={`form-check-label label-product ${
                !selectedCategory ? "active" : ""
              }`}
              htmlFor="radioAllCategories"
              style={{ color: !selectedCategory ? "#cf9a52" : "" }}
            >
              {t("filtermiles.Touslesproduits")}
            </label>
          </div>
        </div>
        {isMobile && isDropdownOpen && (
          <div className="mobile-dropdown">
            <div className="dropdown-menu show">{renderDropdownOptions()}</div>
          </div>
        )}
        {!isMobile && (
          <div className="row catgory-miles m-1">
            {status === "loading" && <div>{t("message.Chargement")}</div>}
            {status === "failed" && <div>{t("message.récupérerlesdonnées")}</div>}
            {status === "succeeded" &&
              categories.map(
                (category) =>
                  category.title === "Miel" &&
                  category.subCategory.map((subCategory, subIndex) => (
                    <div className="col-12" key={subIndex}>
                      <div className="form-check mb-1 radio-item-miel">
                        <input
                          className="form-check-input"
                          type="radio"
                          value={subCategory.title}
                          id={`radioCategory${subIndex}`}
                          checked={selectedCategory === subCategory.title}
                          onChange={handleCategoryChangee}
                        />
                        <label
                          className="form-check-label label-miels"
                          htmlFor={`radioCategory${subIndex}`}
                        >
                          {subCategory.title}{" "}
                        </label>
                      </div>
                    </div>
                  ))
              )}
          </div>
        )}
        <br />
        <br />
      </div>
    </div>
  );
};

export default Filtermiels;
