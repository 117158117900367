import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import { useCart } from "../context/ShoppingCartContext";
import { useTranslation } from "react-i18next";
import PropagateLoader from "react-spinners/PropagateLoader";
import axios from "axios";
import { apiUrl } from "./../url/UrlHelpes";
import { useDispatch, useSelector } from "react-redux";
import { fetchMdDelivery } from "./../features/delivery/DeliverySlice";
import FormatCurrency from "./../function/FormatCurrency";
import DeliveryCategory from "./../data/Delivery.json";
import "./../assets/css/PageCheckout.css";
import Footer from "./../layouts/footer/footer";
import CheckoutCartitmProduct from "../components/shopping/CheckoutCartitmProduct";
import CheckoutCartitmCoffret from "../components/shopping/CheckoutCartitmCoffret";
import CheckoutCartitmPackProduct from "../components/shopping/CheckoutCartitmPackProduct";
import { IoArrowBack } from "react-icons/io5";
import HeaderUpdate from "../layouts/Header/HeaderUpdate";
import imgPanier from "./../assets/images/panier-vide.png";
import MessageSuccess from "./../function/addMessageSuccess";
const PagePanier = () => {
  const locale = localStorage.getItem("i18nextLng");
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { cartItems, clearCart } = useCart();
  const [loading, setLoading] = useState(false);
  const [productCompositionOne, setProductCompositionOne] = useState(null);
  const [productCompositionTwo, setProductCompositionTwo] = useState(null);
  const [price, setPrice] = useState(DeliveryCategory[0].price);
  const { deliverys, status, error } = useSelector((state) => state.deliverys);
  const [selectedDelivery, setSelectedDelivery] = useState(null);

  const getVariantPrice = (product) => {
    const variant130 = product?.variant?.find((v) => v.label === "130");
    return variant130 ? parseFloat(variant130.purchasePriceTTC) : 0;
};


  useEffect(() => {
    dispatch(fetchMdDelivery());
  }, [dispatch]);

  useEffect(() => {
    if (deliverys.length > 0) {
      setPrice(deliverys[0].pricelivery);
      setSelectedDelivery(deliverys[0]);
    }
  }, [deliverys]);

  const handleRadioChange = (event) => {
    const Category = deliverys.find(
      (category) => category.pricelivery === parseFloat(event.target.value)
    );
    if (Category) {
      setPrice(parseFloat(event.target.value));
      setSelectedDelivery(Category);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const promises = cartItems[2].flatMap((item) =>
          item.Products.map((product) =>
            axios.get(`${apiUrl}/products/${product.productId}`)
          )
        );

        const responses = await Promise.all(promises);
        responses.forEach((response, index) => {
          if (index === 0) {
            setProductCompositionOne(response.data);
          } else if (index === 1) {
            setProductCompositionTwo(response.data);
          }
        });
        console.log("Product Composition One:", productCompositionOne);
        console.log("Product Composition Two:", productCompositionTwo);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, [cartItems]);


  let totalPricePack = 0;

  cartItems[2].forEach((item) => {
    let totalCartItem = 0;

    if (item.Products.length > 1) {
      const quantityProduct1 = item.Products[0]?.quantity || 0;
      const quantityProduct2 = item.Products[1]?.quantity || 0;
      const totalPriceItemProduct1 = getVariantPrice(productCompositionOne) * quantityProduct1;
      const totalPriceItemProduct2 = getVariantPrice(productCompositionTwo) * quantityProduct2;

      console.log("Total Price Item Product 1:", totalPriceItemProduct1);
      console.log("Total Price Item Product 2:", totalPriceItemProduct2);
      totalCartItem = totalPriceItemProduct1 + totalPriceItemProduct2;
    } else {
      const quantityProduct1 = item.Products[0]?.quantity || 0;
      const totalPriceItemProduct1 = getVariantPrice(productCompositionOne) * quantityProduct1;
      console.log("Total Price Item Product 1:", totalPriceItemProduct1);
      totalCartItem = totalPriceItemProduct1;
    }

    totalPricePack += totalCartItem;
  });

  const totalItem =
    cartItems[0].reduce((total, item) => {
      return total + (item.categoryPricePoroduct || 0) * item.quantityPoduct;
    }, 0) +
    cartItems[1].reduce((total, item) => {
      return total + (item.priceCoffret || 0) * item.quantityCoffret;
    }, 0) +
    totalPricePack;

  const calculateTotalPrice = () => {
    const totaleFinale = (totalItem + price).toFixed(2);
    return totaleFinale;
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    const token = localStorage.getItem("mbs_user_token");
    const userString = localStorage.getItem("mbs_user");

    if (!token) {
      navigate("/login");
      return;
    }

    if (!userString) {
      console.error("User information is missing");
      return;
    }

    const user = JSON.parse(userString);
    const userId = user.id;

    if (!userId) {
      console.error("User ID is missing");
      return;
    }

    const requestBody = {
      email: data.email,
      telephone: data.telephone,
      firstName: data.firstName,
      lastName: data.lastName,
      codepostal: data.codepostal,
      ville: data.ville,
      pays: data.pays,
      rueInfo: data.rueInfo,
      user: `api/users/${userId}`,
    };

    try {
      setLoading(true);

      const addressResponse = await axios.post(
        `${apiUrl}/user_adresses`,
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (addressResponse.status === 201) {
        const newAddress = addressResponse.data["@id"];

        const orderItems = cartItems.flatMap((cartCategory) =>
          cartCategory.flatMap((item) => {
            const quantity = item.quantityPoduct || item.quantityCoffret || 1;
            const price =
              (item.categoryPricePoroduct || 0) * (item.quantityPoduct || 0) +
              (item.priceCoffret || 0) * (item.quantityCoffret || 0);
        
            const individualProduct = {
              product: item.productId ? `api/products/${item.productId}` : null,
              variant: item.variantId ? `api/variants/${item.variantId}` : null,
              pack: item.packId ? `api/packs/${item.packId}` : null,
              quantity: quantity,
              price: price,
              isvariant: item.categoryName,
            };
        
            const compositionProducts =
              item.Products?.map((product, index) => ({
                product: product.productId
                  ? `api/products/${product.productId}`
                  : null,
                variant: item.variantId
                  ? `api/variants/${item.variantId}`
                  : null,
                pack: item.packId ? `api/packs/${item.packId}` : null,
                quantity: product.quantity || 1,
                isvariant: '130',
                price:
                  (index === 0
                    ? getVariantPrice(productCompositionOne)
                    : getVariantPrice(productCompositionTwo)) *
                  (product.quantity || 1),
                  
              })) || [];
        
            return [individualProduct, ...compositionProducts].filter(
              (orderItem) => orderItem.product !== null
            );
          })
        );

        const orderData = {
          totalPrice: parseFloat(calculateTotalPrice()),
          itemsQuantity: orderItems.length,
          orderNote: data.orderNote || "",
          paymentMethod: "card",
          orderItems,
          user: `api/users/${userId}`,
          adress: newAddress,
          orderStatus: "pending",
          delivery: selectedDelivery
            ? `api/deliveries/${selectedDelivery.id}`
            : null,
        };

        const orderResponse = await axios.post(`${apiUrl}/orders`, orderData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (orderResponse.status === 201) {
          MessageSuccess(t("order.success"));
          navigate("/payment", {
            state: {
              totalPrice: calculateTotalPrice(),
              orderId: orderResponse.data.id,
            },
          });
          clearCart();
        } else {
          throw new Error(orderResponse.data.message || t("order.error"));
        }
      } else {
        throw new Error(addressResponse.data.message || t("contact.error"));
      }
    } catch (error) {
      console.error("Error in order submission:", error);
      if (error.response && error.response.status === 401) {
        MessageSuccess(t("contact.unauthorized"));
      } else {
        console.error("Error:", error);
        MessageSuccess(t("contact.error"));
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <HeaderUpdate />

      {cartItems[0].length === 0 &&
      cartItems[1].length === 0 &&
      cartItems[2].length === 0 ? (
        <div className="container text-center cart-empty">
          <h1 className="mt-5 ">
            {locale === "fr" ? "Mon panier" : "My cart"}
          </h1>
          <img className="img-fluid mt-5" src={imgPanier} alt={imgPanier} />
          <p className="w-100 text-center mt-5 brawn fs-4">
            {locale === "fr"
              ? "Votre panier est actuellement vide."
              : "Your cart is currently empty."}
          </p>
          <Link to="/">
            {locale === "fr"
              ? "Retournez sur la page d’accueil"
              : "Return to the home page"}
          </Link>
        </div>
      ) : (
        <div className="container container-panier mt-5 mb-5">
          <div className="card">
            <div className="row d-flex justify-content-center">
              <div className="col-md-8 cart">
                <div className="title">
                  <div className="row" style={{ color: "#00000080" }}>
                    <div className="col">
                      <h4 className="text-left" style={{ color: "#00000080" }}>
                        <b>{t("PageCheckout.title")}</b>
                      </h4>
                    </div>
                    <div
                      className="col align-self-center text-right text-muted"
                      style={{ color: "#00000080", fontSize: "14px" }}
                    >
                      {t("PageCheckout.lengthProduct")} :{" "}
                      {cartItems[0].length +
                        cartItems[1].length +
                        cartItems[2].length}{" "}
                    </div>
                    <a
                      href="/categorie-miels"
                      className="text-decoration-none mt-2 d-flex justify-content-start"
                      style={{ color: "#00000080" }}
                    >
                      <IoArrowBack style={{ width: "20px", height: "20px" }} />
                      <span
                        className="text-muted"
                        style={{ color: "#00000080", fontSize: "12px" }}
                      >
                        {t("PageCheckout.link-breckout")}
                      </span>
                    </a>
                  </div>
                </div>

                {cartItems[0].map((item) => (
                  <CheckoutCartitmProduct key={item.id} {...item} />
                ))}

                {cartItems[1].map((item) => (
                  <CheckoutCartitmCoffret key={item.id} {...item} />
                ))}

                {cartItems[2].map((composition, index) => (
                  <CheckoutCartitmPackProduct
                    key={index}
                    index={index}
                    composition={composition}
                    cart={cartItems[2]}
                  />
                ))}

                <Accordion
                  className="accordion-panier"
                  defaultActiveKey={["0"]}
                  alwaysOpen
                  style={{ marginTop: "50px" }}
                >
                  <Accordion.Item eventKey="0" style={{ boxShadow: "none" }}>
                    <Accordion.Header
                      className=" Header "
                      style={{ color: "#00000080" }}
                    >
                      {t("PageCheckout.contact.title")}
                    </Accordion.Header>
                    <Accordion.Body className="accordion-body">
                      <form
                        className="row g-3"
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <div className="col-md-12">
                          <label
                            htmlFor="email"
                            className="form-label"
                            style={{ color: "#00000080" }}
                          >
                            {t("PageCheckout.contact.email.title")}*
                          </label>
                          <input
                            type="email"
                            className="form-control text-left"
                            id="email"
                            name="email"
                            placeholder="miels@fcpo.ma"
                            {...register("email", { required: true })}
                          />
                          {errors["email"] && (
                            <span className="text-danger">
                              {t("contact.error")}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label
                            htmlFor="telephone"
                            className="form-label"
                            style={{ color: "#00000080" }}
                          >
                            {t("PageCheckout.contact.phone")}*
                          </label>
                          <input
                            type="text"
                            className="form-control text-left"
                            id="telephone"
                            name="telephone"
                            placeholder="33601227952"
                            {...register("telephone", { required: true })}
                          />
                          {errors["telephone"] && (
                            <span className="text-danger">
                              {t("contact.error")}
                            </span>
                          )}
                        </div>
                      </form>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1" style={{ boxShadow: "none" }}>
                    <Accordion.Header
                      className="Header"
                      style={{ color: "#00000080" }}
                    >
                      {t("PageCheckout.Adslivres.title")}
                    </Accordion.Header>
                    <Accordion.Body className="accordion-body">
                      <form
                        className="row g-3"
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <div className="col-md-6">
                          <label
                            htmlFor="firstName"
                            className="form-label"
                            style={{ color: "#00000080" }}
                          >
                            {t("PageCheckout.Adslivres.firstname.title")}
                          </label>
                          <input
                            type="text"
                            className="form-control text-left"
                            id="firstName"
                            name="firstName"
                            placeholder={t(
                              "PageCheckout.Adslivres.firstname.placeholder"
                            )}
                            {...register("firstName", { required: true })}
                          />
                          {errors["firstName"] && (
                            <span className="text-danger">
                              {t("contact.error")}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label
                            htmlFor="lastName"
                            className="form-label"
                            style={{ color: "#00000080" }}
                          >
                            {t("PageCheckout.Adslivres.lastname.title")}
                          </label>
                          <input
                            type="text"
                            className="form-control text-left"
                            id="lastName"
                            name="lastName"
                            placeholder={t(
                              "PageCheckout.Adslivres.lastname.placeholder"
                            )}
                            {...register("lastName", { required: true })}
                          />
                          {errors["lastName"] && (
                            <span className="text-danger">
                              {t("contact.error")}
                            </span>
                          )}
                        </div>
                        <div className="col-12">
                          <label
                            htmlFor="Address"
                            className="form-label"
                            style={{ color: "#00000080" }}
                          >
                            {t("PageCheckout.Adslivres.address.title")}
                          </label>
                          <input
                            type="text"
                            className="form-control text-left"
                            id="rueInfo"
                            name="rueInfo"
                            placeholder={t(
                              "PageCheckout.Adslivres.address.placeholder"
                            )}
                            {...register("rueInfo", { required: true })}
                          />
                          {errors["rueInfo"] && (
                            <span className="text-danger">
                              {t("contact.error")}
                            </span>
                          )}
                        </div>

                        <div className="col-md-6">
                          <label
                            htmlFor="pays"
                            className="form-label"
                            style={{ color: "#00000080" }}
                          >
                            {t("PageCheckout.Adslivres.country")}{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="pays"
                            name="pays"
                            placeholder="France"
                            {...register("pays", { required: true })}
                          />
                          {errors["pays"] && (
                            <span className="text-danger">
                              {t("contact.error")}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label
                            htmlFor="codepostal"
                            className="form-label"
                            style={{ color: "#00000080" }}
                          >
                            {t("PageCheckout.Adslivres.country_code")}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="codepostal"
                            name="codepostal"
                            placeholder="70123"
                            {...register("codepostal", { required: true })}
                          />
                          {errors["codepostal"] && (
                            <span className="text-danger">
                              {t("contact.error")}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label
                            htmlFor="ville"
                            className="form-label"
                            style={{ color: "#00000080" }}
                          >
                            {t("PageCheckout.Adslivres.city")}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="ville"
                            name="ville"
                            {...register("ville", { required: true })}
                          />
                          {errors["ville"] && (
                            <span className="text-danger">
                              {t("contact.error")}
                            </span>
                          )}
                        </div>
                      </form>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <div className="col-12 mt-5 textarea-panier">
                  <label
                    htmlFor="Address"
                    className="form-label"
                    style={{ color: "#00000080" }}
                  >
                    {t("PageCheckout.comment")}
                  </label>
                  <textarea
                    className="form-control"
                    name="orderNote"
                    id="orderNote"
                    cols="30"
                    rows="10"
                    {...register("orderNote")}
                  ></textarea>
                </div>
              </div>
              <div className="col-md-4 summary">
                <div>
                  <h5 style={{ color: "#cf9a52" }}>
                    <b>{t("PageCheckout.orderSummary")}</b>
                  </h5>
                </div>
                <hr />
                <div className="row">
                  <div
                    className="col"
                    style={{ paddingLeft: "0", color: "#cf9a52" }}
                  >
                    {t("PageCheckout.lengthProduct")} :{" "}
                    {cartItems[0].length +
                      cartItems[1].length +
                      cartItems[2].length}{" "}
                  </div>
                  <div
                    className="col text-right"
                    style={{
                      color: "#cf9a52",
                      fontSize: "15px",
                      marginRight: "35px",
                    }}
                  >
                    {" "}
                    {FormatCurrency(totalItem)}
                  </div>
                </div>
                <form>
                  <div className="col-12">
                    <section className="radio-section">
                      <div className="radio-list">
                        <h6
                          className="text-left mb-2"
                          style={{ color: "#cf9a52" }}
                        >
                          {t("PageCheckout.moddliv")}
                        </h6>
                        {status === "loading" ? (
                          <div className="text-center">
                            <p className="pt-5 pb-5">
                              <PropagateLoader
                                className="mt-2 mb-2"
                                size={5}
                                color={"#cf9a52"}
                              />
                            </p>
                          </div>
                        ) : deliverys && deliverys.length > 0 ? (
                          deliverys.map((Delivery) => (
                            <React.Fragment key={Delivery.id}>
                              <div className="radio-item ">
                                <input
                                  name="radio"
                                  id={`radio${Delivery.id}`}
                                  type="radio"
                                  value={Delivery.pricelivery}
                                  defaultChecked={
                                    price === Delivery.pricelivery
                                  }
                                  onChange={handleRadioChange}
                                />
                                <label
                                  htmlFor={`radio${Delivery.id}`}
                                  className="pe-2 d-flex justify-content-between"
                                  style={{ color: "#00000080" }}
                                >
                                  <span style={{ color: "#00000080" }}>
                                    {Delivery.title}
                                  </span>
                                  <span
                                    style={{
                                      color: "#cf9a52",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {FormatCurrency(Delivery.pricelivery)}
                                  </span>
                                </label>
                              </div>
                            </React.Fragment>
                          ))
                        ) : !error ? (
                          <div className="text-center">No products found</div>
                        ) : (
                          <div className="text-center">{error}</div>
                        )}
                      </div>
                    </section>
                  </div>

                  <div
                    className="row"
                    style={{
                      borderTop: "1px solid rgba(0,0,0,.1)",
                      padding: "2vh 0",
                    }}
                  >
                    <div className="col" style={{ color: "#cf9a52" }}>
                      {t("PageCheckout.subTotal")}
                    </div>
                    <div
                      className="col text-right"
                      style={{
                        fontSize: "15px",
                        color: "#cf9a52",
                        fontWeight: "bold",
                        marginRight: "35px",
                      }}
                    >
                      {FormatCurrency(totalItem)}
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{
                      borderTop: "1px solid rgba(0,0,0,.1)",
                      padding: "2vh 0",
                    }}
                  >
                    <div className="col" style={{ color: "#cf9a52" }}>
                      {t("PageCheckout.shipment")}
                    </div>
                    <div
                      className="col text-right"
                      style={{
                        fontSize: "15px",
                        color: "#cf9a52",
                        fontWeight: "bold",
                        marginRight: "35px",
                      }}
                    >
                      {price.toFixed(2)} &euro;
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{
                      borderTop: "1px solid rgba(0,0,0,.1)",
                      padding: "2vh 0",
                    }}
                  >
                    <div className="col" style={{ color: "#cf9a52" }}>
                      {t("PageCheckout.total")}
                    </div>
                    <div
                      className="col text-right"
                      style={{
                        fontSize: "15px",
                        color: "#cf9a52",
                        fontWeight: "bold",
                        marginRight: "35px",
                      }}
                    >
                      {cartItems.length > 0 ? calculateTotalPrice() : 0} &euro;
                    </div>
                  </div>
                </form>
                <button
                  className="btn btn-adress btn-checkout"
                  disabled={!isValid || loading}
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
                >
                  {loading ? t("contact.loading") : t("contact.aj")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <Footer />
    </>
  );
};

export default PagePanier;
