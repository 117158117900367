import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchBlogById } from "../../features/blog/BlogSlice";
import PropagateLoader from "react-spinners/PropagateLoader";
import { blogImgUrl } from "./../../url/UrlHelpes";
import "../../assets/css/detail-actus.css";
import { decodeHtmlTags } from "@mbs-dev/react-helpers";
import HeaderUpdate from "../../layouts/Header/HeaderUpdate";
import Footer from "../../layouts/footer/footer";


const DetailActus = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { blog, status, error } = useSelector((state) => state.blogs);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(fetchBlogById(id)).then(() => setLoading(false));
  }, [dispatch, id]);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString.createdAt).toLocaleDateString("fr-FR", options);
  };

  return (
    <>
      <HeaderUpdate />
      <div className="container detail-actus-container">
        {status === "loading" || loading ? (
          <div className="text-center">
            <p className="pt-5 pb-5">
              <PropagateLoader size={17} color={"#cf9a52"} />
            </p>
          </div>
        ) : error ? (
          <div className="text-center">{error}</div>
        ) : (
          blog && (
            <div className="detail-actus detail-actus-img-container">
              <img
                src={`${blogImgUrl}/${blog?.image}`}
                className="detail-actus-img"
                alt={`Image ${blog.id}`}
              />
              <div className="detail-actus-info">
                <h2 className="detail-actus-title">{blog.title}</h2>
                <p className="detail-actus-date">{formatDate(blog)}</p>
                <div
                  style={{ fontFamily: "Montserrat-Regular !important" }}
                  className="detail-actus-description"
                  dangerouslySetInnerHTML={{
                    __html: blog?.description
                      ? decodeHtmlTags(blog.description)
                      : "",
                  }}
                ></div>
              </div>
            </div>
          )
        )}
      </div>
      <Footer />

    </>
  );
};

export default DetailActus;
