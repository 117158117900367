import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {apiUrl } from './../../url/UrlHelpes'

export const fetchCover = createAsyncThunk(
  "sliderCover/fetchCover",
  async () => {
    const response = await fetch(`${apiUrl}/special_offers.json`);
    const cover = await response.json();
    return cover;
  }
);

const initialState = {
  cover: [],
  status: null,
};

const SliderCoverSlice = createSlice({
  name: "SliderCover",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCover.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCover.fulfilled, (state, { payload: cover }) => {
        state.status = "succeeded";
        state.cover = cover;
      })
      .addCase(fetchCover.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default SliderCoverSlice.reducer